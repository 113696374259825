<div class="text-center px-2 py-4 md:px-6 lg:px-8">
  <steps-router></steps-router>

  <div class="px-4 py-2 md:px-6 lg:px-8">
    <div class="text-900 font-bold text-6xl mb-4 text-center">Metoda płatności</div>
    <div class="text-700 text-xl mb-6 text-center line-height-3">Wybierz dogodną formę płatności</div>

    <div class="grid">
      @if (this.reservation.totalBruttoPrice < 4200) {
        <div class="col-12 px-1 py-4">
          <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
            <div class="p-4">
              <div class="flex align-items-center">
                        <span class="inline-flex border-circle align-items-center justify-content-center bg-blue-100 mr-3" style="width:38px;height:38px">
                            <i class="pi pi-briefcase text-xl text-blue-600"></i>
                        </span>
                <span class="text-900 font-medium text-2xl">Szybka płatność</span>
              </div>
              <div class="text-900 my-3 text-xl font-medium">Przelew elektroniczny</div>
              <p class="mt-0 mb-3 text-700 line-height-3">
                Skorzystaj z szybkiej płatności online.
                Wystarczy, że klikniesz przycisk poniżej, a zostaniesz przekierowany do bramki płatności.
                Dokonasz przelewu w kilku prostych krokach.
              </p>
            </div>
            <div class="px-4 py-3 surface-100 text-right">
              <a pButton pRipple class="p-button-rounded p-button-info no-underline" target="_blank" href="google.com/pl">Otwórz bramkę płatności <i class="fa-solid fa-money-bill-transfer ml-2"></i></a>
            </div>
          </div>
        </div>
      }
      <div class="col-12 px-1 py-4">
        <div class="shadow-2 border-round surface-card mb-3 h-full flex-column justify-content-between flex">
          <div class="p-4">
            <div class="flex align-items-center">
              <span class="inline-flex border-circle align-items-center justify-content-center bg-green-100 mr-3" style="width:38px;height:38px">
                  <i class="pi pi-globe text-xl text-green-600"></i>
              </span>
              <span class="text-900 font-medium text-2xl">Przelew tradycyjny</span>
            </div>
            <div class="text-900 my-3 text-xl font-medium">Wykonaj przelew na poniższe dane</div>
            <p class="mt-0 mb-3 text-700 line-height-3">
              Wykonaj przelew w tradycyjnej formie i przejdź do podsumowania.
            </p>
            <p class="mt-0 mb-1 text-700 line-height-3">Tytuł przelewu: </p>
            <p class="mt-0 mb-1 text-700 line-height-3">Numer konta: </p>
            <p class="mt-0 mb-1 text-700 line-height-3">Bank: </p>
            <p class="mt-0 mb-1 text-700 line-height-3">Kod SWIFT: </p>
            <p class="mt-0 mb-1 text-700 line-height-3">Nazwa odbiorcy: </p>
            <p class="mt-0 mb-1 text-700 line-height-3">Adres odbiorcy: </p>
            <p class="mt-0 mb-1 text-700 line-height-3">Kwota przelewu: </p>
          </div>
          <div class="px-4 py-3 surface-100 text-right">
            <button pButton pRipple icon="fa-solid fa-landmark" iconPos="right" label="Zobacz podsumowanie" class="p-button-rounded p-button-success"></button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <pre> {{ this.reservation | json }} </pre>
</div>
