import { Component, OnDestroy, OnInit } from "@angular/core";
import { CreatorService } from "../../shared/data/services/creator.service";
import { Router } from "@angular/router";

@Component({
  selector: 'order-payment',
  templateUrl: './order-payment.component.html',
  styleUrl: './order-payment.component.scss'
})
export class OrderPaymentComponent implements OnInit, OnDestroy {
  reservation: any = {};

  constructor(protected creatorService: CreatorService, private router: Router) {}

  ngOnInit() {
    this.reservation = this.creatorService.getSessionStorageItem("reservationSession", {});
  }

  ngOnDestroy() {

  }

}
