export class Address {
  street?: string;
  streetNumber?: string;
  propertyNumber?: string;
  postalCode?: string;
  town?: string;
  mapUrl?: string

  static printAddress(address: Address): string {
    return address.mapUrl ?
      `<a href="${address.mapUrl}" target="_blank"><i class="fa-solid fa-location-dot"></i> ${address.street} ${address.streetNumber}${address.propertyNumber ? ` / ${address.propertyNumber}` : ""},<br/> ${address.postalCode} ${address.town}</a>`
      : `<span><i class="fa-solid fa-location-dot"></i> ${address.street} ${address.streetNumber}${address.propertyNumber ? ` / ${address.propertyNumber}` : ""},<br/> ${address.postalCode} ${address.town}</span>`
    ;
  }
}
