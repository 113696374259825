<div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12">
      <p-table #dt1 [value]="storageContainers" [scrollable]="true" scrollHeight="64vh" [tableStyle]="{'min-width': '50rem'}"
               dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5, 10, 15, 25, 50, 100]" [loading]="loading"
               [paginator]="true" [showJumpToPageInput]="true" currentPageReportTemplate="Wiersze: {first} - {last} / {totalRecords}, Strona: {currentPage} / {totalPages}"
               [globalFilterFields]="['storageNumber', 'location.name', 'status']" sortMode="multiple"
               [(selection)]="selectedStorageContainers" styleClass="p-datatable-striped" loadingIcon="fa-solid fa-rotate fa-spin"
      >
        <ng-template pTemplate="caption">
          <p-toolbar styleClass="mb-4 gap-2">
            <ng-template pTemplate="left">
              <p-button
                pRipple severity="success" label="Dodaj" icon="fa-solid fa-plus"
                class="mr-2" (click)="showStorageContainersDialog = true;"
              >
              </p-button>
              <p-button
                pRipple severity="danger" label="Usuń" icon="fa-regular fa-trash-can"
                class="mr-2" (click)="confirmDialog($event); console.log('delete'); console.log(selectedStorageContainers);"
                [disabled]="!selectedStorageContainers || (selectedStorageContainers && selectedStorageContainers.length === 0)"
              >
              </p-button>
              <!--<p-button
                pRipple severity="info" label="Zmień statusy" icon="fa-solid fa-pen-to-square"
                class="mr-2" (click)="console.log('change statuses');"
              >
              </p-button>-->
            </ng-template>
            <!--<ng-template pTemplate="right">
              <p-fileUpload
                mode="basic"
                accept="image/*"
                [maxFileSize]="1000000"
                label="Import"
                chooseLabel="Import"
                class="mr-2 inline-block" />
              <p-button
                pRipple severity="help"
                label="Export" icon="pi pi-upload" />
            </ng-template>-->
          </p-toolbar>
          <p-confirmDialog>
            <ng-template pTemplate="header" let-header>
              <span class="p-text-bold">{{header}}</span>
            </ng-template>
            <ng-template pTemplate="message" let-message>
              <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
                <i class="fa-regular fa-trash-can text-6xl text-primary-500"></i>
                <div [innerHtml]="message.message | sanitizeHtml"></div>
              </div>
            </ng-template>
          </p-confirmDialog>
          <!--<div class="flex flex-row justify-content-between">
            <div class="flex align-items-center justify-content-between">
              <span class="p-input-icon-left mr-1">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="searchInput(dt1, $event);" placeholder="Search keyword" />
              </span>
              <button pButton label="Wyczyść filtry" class="p-button-outlined ml-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
            </div>
          </div>-->
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem;">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th style="min-width: 12rem;" pSortableColumn="storageNumber">
              <div class="flex align-items-center justify-content-around">
                Numer kontenera
                <p-sortIcon field="storageNumber"></p-sortIcon>
                <p-columnFilter class="ml-1" field="storageNumber" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem" pSortableColumn="group.name">
              <div class="flex align-items-center">
                Rodzaj kontenera
                <p-sortIcon class="mx-1" field="group.name"></p-sortIcon>
                <p-columnFilter field="group.name" matchMode="in" display="menu"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false"
                                [showClearButton]="false" [showApplyButton]="false"
                >
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">Wybierz rodzaj</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-filteredGroup let-filter="filterCallback">
                    <p-multiSelect [ngModel]="filteredGroup" [options]="storageGroups" placeholder="Wybierz..." (onChange)="filter($event.value);" optionLabel="name"
                                   optionValue="name">
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <span class="ml-1 mt-1">{{ option.name }}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem;" pSortableColumn="location.name">
              <div class="flex align-items-center justify-content-around">
                Lokalizacja
                <p-sortIcon field="location.name"></p-sortIcon>
                <p-columnFilter field="location.name" matchMode="in" display="menu" [showMatchModes]="false"
                                [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false"
                >
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">Wybierz lokalizacje</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-multiSelect [ngModel]="value" [options]="locationList" placeholder="Wybierz..."
                                   (onChange)="filter($event.value);" optionLabel="name" optionValue="name" display="chip"
                    >
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <span class="ml-1 mt-1">{{ option.name }}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th style="min-width:12rem" pSortableColumn="status">
              <div class="flex align-items-center justify-content-around">
                Status
                <p-sortIcon field="status"></p-sortIcon>
                <p-columnFilter field="status" matchMode="equals" display="menu" [showMenu]="true" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="storageStatuses" placeholder="Wybierz status..." (onChange)="console.log('$event: ', $event); filter($event.value)" [showClear]="true" >
                      <ng-template pTemplate="selectedItem" let-selectedItem>
                        <p-tag [value]="selectedItem.name" [severity]="getSeverity(selectedItem.value)"></p-tag>
                      </ng-template>
                      <ng-template pTemplate="item" let-option>
                        <p-tag [value]="option.name" [severity]="getSeverity(option.value)"></p-tag>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-storageContainer let-rowIndex="rowIndex">
          <tr [pSelectableRow]="storageContainer" [pSelectableRowIndex]="rowIndex" (click)="openRow($event, storageContainer.id);">
            <td class="disableSelect">
              <p-tableCheckbox [value]="storageContainer"></p-tableCheckbox>
            </td>
            <td>
              {{ storageContainer.storageNumber }}
            </td>
            <td>
              {{ storageContainer.group.name }}
            </td>
            <td>
              <span class="ml-1 vertical-align-middle">{{ storageContainer.location.name }}</span>
            </td>
            <td>
              <p-tag [value]="getValueStatus(storageContainer.status)" [severity]="getSeverity(storageContainer.status)"></p-tag>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No customers found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
<p-toast />

<!-- Okno dodawania -->
<form [formGroup]="storageContainerAddForm" (ngSubmit)="onSubmitDialog();">
  <p-dialog
    [(visible)]="showStorageContainersDialog" [modal]="true"
    resizable="true"
    [style]="{ width: '450px', 'min-height': '35rem', 'min-width': '250px' }"
    header="Dodawanie magazynu" styleClass="p-fluid"
    (onHide)="hideDialog()"
  >
    <ng-template pTemplate="content">
      <div class="grid py-3">
        <div class="col-12 field">
          <p-floatLabel>
            <input
              pInputText id="storageNumber" formControlName="storageNumber"
              type="text" required autofocus
              [ngClass]="{
                'ng-invalid': storageContainerAddForm.controls.storageNumber.invalid && (storageContainerAddForm.controls.storageNumber.dirty || storageContainerAddForm.controls.storageNumber.touched) && storageContainerAddForm.controls.storageNumber.errors?.required,
                'ng-dirty': storageContainerAddForm.controls.storageNumber.invalid && (storageContainerAddForm.controls.storageNumber.dirty || storageContainerAddForm.controls.storageNumber.touched) && storageContainerAddForm.controls.storageNumber.errors?.required
              }"
            />
            <label for="storageNumber">Numer kontenera</label>
          </p-floatLabel>
          <div
            *ngIf="storageContainerAddForm.controls.storageNumber.invalid && (storageContainerAddForm.controls.storageNumber.dirty || storageContainerAddForm.controls.storageNumber.touched)">
            <small *ngIf="storageContainerAddForm.controls.storageNumber.errors?.required" class="p-error ml-3">
              Pole `Numer kontenera` jest wymagany.
            </small>
          </div>
        </div>
        <div class="col-12 field">
          <p-floatLabel>
            <p-dropdown
              inputId="storageGroup" id="storageGroupDropDown" formControlName="group"
              [options]="storageGroups" optionLabel="name" optionValue="name"
              [ngClass]="{
                'ng-invalid': storageContainerAddForm.controls.group.invalid && (storageContainerAddForm.controls.group.dirty || storageContainerAddForm.controls.group.touched),
                'ng-dirty': storageContainerAddForm.controls.group.invalid && (storageContainerAddForm.controls.group.dirty || storageContainerAddForm.controls.group.touched)
              }"
            >
              <ng-template let-option pTemplate="item">
                {{ option.name }}
              </ng-template>
            </p-dropdown>
            <label for="storageGroup">Rodzaj kontenera</label>
          </p-floatLabel>
          <div
            *ngIf="storageContainerAddForm.controls.group.invalid && (storageContainerAddForm.controls.group.dirty || storageContainerAddForm.controls.group.touched)">
            <small *ngIf="storageContainerAddForm.controls.group.errors?.required" class="p-error ml-3">
              Pole `Rodzaj kontenera` jest wymagany.
            </small>
          </div>
        </div>
        <div class="col-12 field">
          <p-floatLabel>
            <p-autoComplete id="storageStatusDropDown" inputId="storageStatus" formControlName="status"
                            [suggestions]="filteredStatuses" [dropdown]="true" [forceSelection]="true"
                            [autoOptionFocus]="true"
                            autocomplete="autocomplete" optionLabel="name" optionValue="value" appendTo="body"
                            (completeMethod)="filterStatuses($event)" (onSelect)="selectedStatus = $event.value"
                            [inputStyle]="{'background-color': selectedStatus?.backgroundColor, color: (selectedStatus?.value != 'reserved' ? '#fff' : '#000'), 'font-weight': '600'}"
                            [ngClass]="{
                              'ng-invalid': storageContainerAddForm.controls.status.invalid && (storageContainerAddForm.controls.status.dirty || storageContainerAddForm.controls.status.touched),
                              'ng-dirty': storageContainerAddForm.controls.status.invalid && (storageContainerAddForm.controls.status.dirty || storageContainerAddForm.controls.status.touched),
                            }"
            >
              <ng-template let-storageStatus pTemplate="item">
                <div class="flex align-items-center gap-2 p-0">
                  <p-tag
                    styleClass="text-sm" [value]="storageStatus.name"
                    [severity]="getSeverity(storageStatus.value)" />
                </div>
              </ng-template>
            </p-autoComplete>
            <label for="storageStatus">Status kontenera</label>
          </p-floatLabel>
          <div
            *ngIf="storageContainerAddForm.controls.status.invalid && (storageContainerAddForm.controls.status.dirty || storageContainerAddForm.controls.status.touched)">
            <small *ngIf="storageContainerAddForm.controls.status.errors?.required" class="p-error ml-3">
              Pole `Status kontenera` jest wymagany.
            </small>
          </div>
        </div>
        @if (this.storageContainerAddForm.controls.notes.enabled) {
          <div class="col-12 field">
            <p-editor id="notes" [style]="{ 'min-height': '120px' }" placeholder="Notatka do statusu" formControlName="notes"></p-editor>
          </div>
        }
        <div class="col-12 field">
          <p-floatLabel>
            <p-autoComplete [dropdown]="true" [forceSelection]="true" [autoOptionFocus]="true"
                            [suggestions]="filteredLocations"
                            (completeMethod)="filterLocations($event)" autocomplete="autocomplete" optionLabel="name"
                            formControlName="location"
                            inputId="storageLocation" id="storageLocationDropDown" appendTo="body"
                            [ngClass]="{
                              'ng-invalid': storageContainerAddForm.controls.location.invalid && (storageContainerAddForm.controls.location.dirty || storageContainerAddForm.controls.location.touched),
                              'ng-dirty': storageContainerAddForm.controls.location.invalid && (storageContainerAddForm.controls.location.dirty || storageContainerAddForm.controls.location.touched),
                            }"
            >
              <ng-template let-location pTemplate="item">
                <div class="flex align-items-center">
                  <span>{{ location.name }}</span>
                </div>
              </ng-template>
            </p-autoComplete>
            <label for="storageLocation" class="">Lokalizacja</label>
          </p-floatLabel>
          <div
            *ngIf="storageContainerAddForm.controls.location.invalid && (storageContainerAddForm.controls.location.dirty || storageContainerAddForm.controls.location.touched)">
            <small *ngIf="storageContainerAddForm.controls.location.errors?.required" class="p-error ml-3">
              Pole `Lokalizacja` jest wymagany.
            </small>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-button
        pRipple label="Anuluj" icon="fa-solid fa-xmark" [text]="true"
        (click)="hideDialog()"
      />
      <p-button
        pRipple label="Zapisz" icon="fa-solid fa-floppy-disk" [text]="true" type="submit"
      />
    </ng-template>
  </p-dialog>
</form>
