import { Component, Input } from "@angular/core";
import { ButtonModule } from 'primeng/button';
import { NgClass, NgIf } from "@angular/common";

@Component({
  selector: 'footer',
  standalone: true,
  imports: [
    ButtonModule,
    NgClass,
    NgIf,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  @Input({required: true}) isPanel!: boolean;
}
