import { Component } from '@angular/core';

@Component({
  selector: 'panel-home',
  templateUrl: './panel-home.component.html',
  styleUrl: './panel-home.component.scss'
})
export class PanelHomeComponent {

  constructor() {

  }

}
