export enum discountType {
  percentage,
  amount
}

export class DiscountCode {
  id: string;
  discountCode: string;
  typeDiscount: discountType;
  discountValue: number;

  constructor(id: string = '', discountCode: string = '', typeDiscount: discountType = discountType.percentage, discountValue: number = 0) {
    this.id = id;
    this.discountCode = discountCode;
    this.typeDiscount = typeDiscount;
    this.discountValue = discountValue;
  }

}
