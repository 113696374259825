import { Component, OnInit } from "@angular/core";
import { FetchDataService } from "../../../shared/data/services/fetch-data.service";
import { FilterService, Message } from "primeng/api";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CreatorService } from "../../../shared/data/services/creator.service";
import dayjs from "dayjs";

interface EventItem {
  status?: string;
  date?: string;
  icon?: string;
  color?: string;
  image?: string;
}

@Component({
  selector: 'storage-container-details',
  templateUrl: './storage-container-details.component.html',
  styleUrl: './storage-container-details.component.scss'
})
export class StorageContainerDetailsComponent implements OnInit {
  events: EventItem[] = [];

  storageStatuses?: any[] = [];
  selectedStatus: any;
  filteredStatuses: any[] = [];

  storageGroups: any[] = [];
  selectedGroup: any;
  filteredStorageGroups: any[] = [];

  locations: any[] = [];
  selectedLocation: any;
  filteredLocations: any[] = [];

  protected readonly console = console;
  storageContainerDataForm: FormGroup;
  messages: Message[] = [];

  constructor(
    private readonly router: ActivatedRoute,
    private readonly creatorService: CreatorService,
    private readonly fetchData: FetchDataService,
    private readonly filterService: FilterService,
    private fb: FormBuilder
  ) {
    this.storageContainerDataForm = this.fb.group({
      storageNumber: [{ value: '', disabled: false }, []],
      status: [{ value: '', disabled: false }, []],
      group: [{ value: '', disabled: false }, []],
      location: [{ value: '', disabled: false }, []],
      notes: [{ value: '', disabled: true }, []],
    });
  }

  ngOnInit(): void {

    this.fetchData.getStorageStatuses().then((statuses) => {
      this.storageStatuses = statuses;

      this.fetchData.getStorageContainer(this.router.snapshot.params.id).subscribe({
        next: (response: any) => {
          if (response.status === 200) {
            this.selectedStatus = statuses.filter((item) => item.value == response.body.status).shift();
            if (this.selectedStatus && this.selectedStatus.name == 'Serwis') {
              this.storageContainerDataForm.controls.notes.enable();
              this.storageContainerDataForm.controls.notes.setValidators([Validators.required]);
            } else {
              this.storageContainerDataForm.controls.notes.disable();
              this.storageContainerDataForm.controls.notes.clearValidators();
            }
            this.storageContainerDataForm.patchValue({
              storageNumber: response.body.storageNumber,
              status: this.selectedStatus,
              group: response.body.group,
              location: response.body.location,
              notes: response.body.notes
            });

            console.log("this.storageContainerDataForm: ", this.storageContainerDataForm);
          } else {
            throw new Error('Error fetching storage container');
          }
        }
      });

      this.fetchData.getLocations().subscribe((response: any) => {
        if (response.status === 200) {
          this.locations = response.body;
        }
      });

    });

    this.storageGroups = [
      {
        id: 1,
        name: "Magazyn 7m2",
      },
      {
        id: 2,
        name: "Magazyn 14m2",
      },
      {
        id: 3,
        name: "Magazyn 29m2",
      }
    ];

    this.events = [
      { status: 'Ordered', date: '15/10/2020 10:30', icon: 'pi pi-shopping-order', color: '#9C27B0', image: 'game-controller.jpg' },
      { status: 'Processing', date: '15/10/2020 14:00', icon: 'pi pi-cog', color: '#673AB7' },
      { status: 'Shipped', date: '15/10/2020 16:15', icon: 'pi pi-shopping-cart', color: '#FF9800' },
      { status: 'Delivered', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
    ];

    this.storageContainerDataForm.controls.status.valueChanges.subscribe((value) => {
      console.log("status valueChanges: ", value);
      if (value.name == 'Serwis') {
        this.storageContainerDataForm.controls.notes.enable();
        this.storageContainerDataForm.controls.notes.setValidators([Validators.required]);
      } else {
        this.storageContainerDataForm.controls.notes.disable();
        this.storageContainerDataForm.controls.notes.clearValidators();
      }
    });
  }

  filterStatuses(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.storageStatuses as any[]).length; i++) {
      let storageStatus = (this.storageStatuses as any[])[i];
      if (storageStatus.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(storageStatus);
      }
    }

    this.filteredStatuses = filtered;
  }

  filterLocations(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.locations.length; i++) {
      let location = this.locations[i];
      if (location.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
        filtered.push(location);
      }
    }

    this.filteredLocations = filtered;
  }

  filterGroups(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.storageGroups.length; i++) {
      let storageGroup = this.storageGroups[i];
      if (storageGroup.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
        filtered.push(storageGroup);
      }
    }

    this.filteredStorageGroups = filtered;
  }

  onSubmitDataTab() {
    if (this.storageContainerDataForm.dirty) {
      const form: any = {
        storageNumber: this.storageContainerDataForm.controls.storageNumber.value,
        status: (typeof this.storageContainerDataForm.controls.status.value) === 'object' ?
          this.storageContainerDataForm.controls.status.value.value :
          this.storageContainerDataForm.controls.status.value,
        group: this.storageContainerDataForm.controls.group.value.id,
        location: this.storageContainerDataForm.controls.location.value.id,
      };

      if (this.storageContainerDataForm.controls.notes.enabled) {
        form['notes'] = this.storageContainerDataForm.controls.notes.value;
      }

      this.creatorService.saveStorageContainer(
        this.router.snapshot.params.id, form).subscribe({
          next: (response: any) => {
            console.log("response.status: ", response.status);
            if (response.status == 200) {
              console.log("Storage container saved");
              this.messages = [{
                icon: 'fa-regular fa-circle-check',
                severity: 'success',
                summary: `[${dayjs().format("HH:mm:ss")}] Sukces: `,
                life: 7000,
                detail: 'Zmiany zostały zapisane prawidłowo.'
              }];
            } else {
              this.messages = [{
                icon: 'fa-solid fa-triangle-exclamation',
                severity: 'warn',
                summary: `[${dayjs().format("HH:mm:ss")}] Uwaga: `,
                life: 7000,
                detail: 'Wystąpił błąd podczas zapisu kontenera magazynowego.'
              }];
              throw new Error('Error saving storage container');
            }
          },
          error: (err) => {
            console.error(err);
            this.messages = [{
              icon: 'fa-regular fa-circle-xmark',
              severity: 'error',
              summary: `[${dayjs().format("HH:mm:ss")}] Błąd(${err.status}): `,
              life: 7000,
              detail: 'Wystąpił błąd podczas zapisu kontenera magazynowego.'
            }];
            throw new Error('Error saving storage container');
          }
        });
    } else {
      this.messages = [{
        icon: 'fa-solid fa-circle-info',
        severity: 'info',
        summary: `[${dayjs().format("HH:mm:ss")}] Info: `,
        life: 7000,
        detail: 'Nie dokonano żadnych zmian.'
      }];
    }
  }
}
