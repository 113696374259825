import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FileUploadEvent } from "primeng/fileupload";
import { MenuItem, Message } from "primeng/api";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { FetchDataService } from "../../../shared/data/services/fetch-data.service";
import { CreatorService } from "../../../shared/data/services/creator.service";
import { forkJoin } from "rxjs";
import { HttpStatusCode } from "@angular/common/http";

interface EventItem {
  status?: string;
  date?: string;
  icon?: string;
  color?: string;
  image?: string;
}

@Component({
  selector: "reservation-details",
  templateUrl: "./reservation-details.component.html",
  styleUrl: "./reservation-details.component.scss",
})
export class ReservationDetailsComponent implements OnInit {

  protected readonly console = console;
  reservationId!: string;
  reservationData: any = {
    reservationForm: {
      subjectData: {},
      location: {},
    },
    reservationDetails: [],
  };
  reservationsStatuses: any[] = [
    { label: "-", value: "" },
    { label: "Rozpoczęta", value: "open" },
    { label: "Złożona", value: "submitted" },
    { label: "Przetwarzanie płatności", value: "payment processing" },
    { label: "Oczekuje na płatność", value: "awaiting payment" },
    { label: "Opłacona", value: "paid" },
    { label: "Potwierdzona", value: "confirmed" },
    { label: "W trakcie", value: "pending" },
    { label: "Gotowa", value: "ready" },
    { label: "Zakończona", value: "completed" },
    { label: "Anulowana", value: "cancelled" },
    { label: "Odrzucona", value: "rejected" },
  ];
  availableContainers: any[] = [];
  selectedContainer: any[] = [];
  currentTab: number = 1;
  uploadedFiles: any[] = [];
  events: EventItem[];
  messages: Message[] = [];
  containersTableMessage: Message[] = [];
  reservationDataForm: FormGroup;
  formSentinel: any = {
    downloadContract: false,
    signedContract: false,
    submitted: false,
    paymentMethod: "",
    formId: null,
  };
  private lockedContainer: any[] = [];
  filterValue: string | undefined = "";
  toolbox: MenuItem[] | undefined;

  constructor(
    private activatedRoute: ActivatedRoute,
    protected readonly creatorService: CreatorService,
    private readonly fetchData: FetchDataService,
    private fb: FormBuilder,
  ) {
    this.activatedRoute.params.subscribe((params: any) => {
      this.reservationId = params.id;
    }).unsubscribe();

    this.reservationDataForm = this.fb.group({
      reservationStatus: [{ value: "", disabled: false }, [Validators.required]],
      // reservationForm
      email: [{ value: "", disabled: true }, [Validators.required, Validators.email]],
      // phoneNumber: [{ value: "", disabled: false }, [Validators.required]],
      // countryCode: [{ value: "", disabled: false }, [Validators.required]],
      phone: [{ value: "", disabled: true }, [Validators.required, Validators.pattern("^[0-9]{9,}$")]],
      subjectType: [{ value: null, disabled: true }, [Validators.required]],
      // subjectType = company
      companyName: [{ value: "", disabled: true }],
      // companyType: [{ value: null, disabled: true }],
      nipNumber: [{ value: "", disabled: true }],
      // regonNumber: [{ value: '', disabled: true }],
      // krsNumber: [{ value: '', disabled: true }],
      // subjectType = person
      surNames: [{ value: "", disabled: true }],
      lastName: [{ value: "", disabled: true }],
      hasPESEL: [{ value: null, disabled: true }, [Validators.required]],
      PESEL: [{ value: "", disabled: true }],
      passportNumber: [{ value: "", disabled: true }],
      addressName: [{ value: "", disabled: true }, [Validators.required]],
      addressNumber: [{ value: "", disabled: true }, [Validators.required]],
      addressApartment: [{ value: "", disabled: true }],
      postalCode: [{ value: "", disabled: true }, [Validators.required]],
      town: [{ value: "", disabled: true }, [Validators.required]],
      atos: [{ value: false, disabled: true }, [Validators.requiredTrue]],
      paf: [{ value: false, disabled: true }, [Validators.requiredTrue]],
      pas: [{ value: false, disabled: true }, [Validators.requiredTrue]],
      krdCheck: [{ value: false, disabled: true }],
      newsletterCheck: [{ value: false, disabled: true }],
    });

    this.events = [
      { status: "Ordered", date: "15/10/2020 10:30", icon: "pi pi-shopping-reservationDetails", color: "#9C27B0", image: "game-controller.jpg" },
      { status: "Processing", date: "15/10/2020 14:00", icon: "pi pi-cog", color: "#673AB7" },
      { status: "Shipped", date: "15/10/2020 16:15", icon: "pi pi-shopping-reservationDetails", color: "#FF9800" },
      { status: "Delivered", date: "16/10/2020 10:00", icon: "pi pi-check", color: "#607D8B" },
    ];

  }

  ngOnInit() {
    this.toolbox = [
      {
        label: "Drukuj",
        items: [
          {
            label: "Umowa końcowa",
            icon: "fa-solid fa-file-pen",
            command: () => {
              console.log("Drukuję umowę końcową");
              window.open(`/api/templates/contract/${this.reservationId}`, "_blank");
            },
          },
        ],
      },
      {
        label: "Narzędzia",
        items: [
          {
            label: "Wyślij e-mail z umową",
            icon: "fa-solid fa-paper-plane",
            command: () => {
              console.log("Otwieram moduł wysyłki e-mail");
            },
          },
        ],
      },
    ];
    this.fetchData.getReservation(this.reservationId).subscribe({
      next: (response: any) => {
        if (response.status === HttpStatusCode.Ok) {
          console.log("getReservation");
          Object.keys(response.body.reservationForm.subjectData).forEach((row) => {
            response.body.reservationForm[row] = response.body.reservationForm.subjectData[row];
          });
          response.body.reservationForm.hasPESEL = !!response.body.reservationForm.PESEL;
          response.body.reservationForm.krdCheck = !!response.body.reservationForm.krdCheck;
          response.body.reservationForm.newsletterCheck = !!response.body.reservationForm.newsletterCheck;
          response.body.reservationForm.phone = response.body.reservationForm.phone.number;

          this.reservationData = response.body;
          console.log("> reservationData: ");
          console.log(this.reservationData);
          this.selectedContainer = this.reservationData.storageContainers;
          // this.uploadedFiles = this.reservationData.reservationForm.contractFiles;
          this.reservationDataForm.setValue({
            reservationStatus: this.reservationData.reservationStatus,
            email: this.reservationData.reservationForm.email,
            phone: this.reservationData.reservationForm.phone,
            subjectType: this.reservationData.reservationForm.subjectType,
            companyName: this.reservationData.reservationForm.companyName,
            nipNumber: this.reservationData.reservationForm.nipNumber,
            surNames: this.reservationData.reservationForm.surNames,
            lastName: this.reservationData.reservationForm.lastName,
            hasPESEL: this.reservationData.reservationForm.hasPESEL,
            PESEL: this.reservationData.reservationForm.PESEL,
            passportNumber: this.reservationData.reservationForm.passportNumber,
            addressName: this.reservationData.reservationForm.addressName,
            addressNumber: this.reservationData.reservationForm.addressNumber,
            addressApartment: this.reservationData.reservationForm.addressApartment,
            postalCode: this.reservationData.reservationForm.postalCode,
            town: this.reservationData.reservationForm.town,
            atos: this.reservationData.reservationForm.atos,
            paf: this.reservationData.reservationForm.paf,
            pas: this.reservationData.reservationForm.pas,
            krdCheck: this.reservationData.reservationForm.krdCheck,
            newsletterCheck: this.reservationData.reservationForm.newsletterCheck,
          });
          if (this.reservationData.hasOwnProperty("reservationForm")) {
            this.reservationDataForm.patchValue({
              email: this.reservationData.reservationForm.email,
              phone: this.reservationData.reservationForm.phone,
              subjectType: this.reservationData.reservationForm.subjectType,
              companyName: this.reservationData.reservationForm.companyName,
              nipNumber: this.reservationData.reservationForm.nipNumber,
              surNames: this.reservationData.reservationForm.surNames,
              lastName: this.reservationData.reservationForm.lastName,
              hasPESEL: this.reservationData.reservationForm.hasPESEL,
              PESEL: this.reservationData.reservationForm.PESEL,
              passportNumber: this.reservationData.reservationForm.passportNumber,
              addressName: this.reservationData.reservationForm.addressName,
              addressNumber: this.reservationData.reservationForm.addressNumber,
              addressApartment: this.reservationData.reservationForm.addressApartment,
              postalCode: this.reservationData.reservationForm.postalCode,
              town: this.reservationData.reservationForm.town,
              atos: this.reservationData.reservationForm.atos,
              paf: this.reservationData.reservationForm.paf,
              pas: this.reservationData.reservationForm.pas,
              krdCheck: this.reservationData.reservationForm.krdCheck,
              newsletterCheck: this.reservationData.reservationForm.newsletterCheck,
            });
          }
        } else {
          throw new Error("Error fetching reservation");
        }
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }

  onUpload(event: FileUploadEvent) {

    console.log(event.files);

    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

  }

  onSubmitDataTab() {
    console.log("onSubmitDataTab: ");
    console.log(this.reservationData);
    console.log(this.reservationDataForm);
    this.creatorService.updateReservation(this.reservationId, this.reservationDataForm.value).subscribe((response: any) => {
      if (response.response.statusCode === 200) {
        // ToDo: dodać wysyłkę e-mail na podstawie zmiany statusu rezerwacji.
        console.log("Reservation updated");
        this.messages.push({
          severity: "success",
          summary: "Zapisano",
          detail: "Dane rezerwacji zostały zapisane",
        });
      } else {
        this.messages.push({
          severity: "warning",
          summary: "Błąd: " + response.response.statusCode,
          detail: "Błąd zapisu danych rezerwacji",
        });
        throw new Error("Error updating reservation");
      }
    });
  }

  onRowEditInit(container: any) {
    /*console.log("onRowEditInit");
    console.log(container);*/
    forkJoin([
      this.fetchData.getReservation(this.reservationId),
      this.fetchData.getAvailableStorageContainers(this.reservationData),
    ]).subscribe(([reservation, availableStorages]) => {
      if (reservation.status === 200) {
        this.reservationData = reservation.body;
      } else {
        throw new Error("Error fetching reservation");
      }

      this.lockedContainer = this.reservationData.storageContainers
        .filter((item: any) => item.id === container.id);
      if (availableStorages.status === HttpStatusCode.Ok) {
        this.availableContainers = [
          {
            label: "Zarezerwowane:",
            value: "reserved",
            items: this.lockedContainer,
          },
          { label: "Wolne:", value: "available", items: availableStorages.body },
        ];
      } else {
        throw new Error("Error fetching available storage containers");
      }
    });
  }

  onRowEditSave(container: any, inx: number) {
    console.log("onRowEditSave");
    console.log(this.selectedContainer);
    console.log(container);
    this.creatorService.saveReservedContainer(this.reservationId, this.availableContainers[1].items.filter((item: any) => item.id == container.id)).subscribe((response: any) => {
      if (response.response.statusCode === 200) {
        this.ngOnInit();
        this.containersTableMessage.push({
          severity: "success",
          summary: "Zapisano",
          detail: `Zarezerwowano kontener ${container.storageNumber} dla rezerwacji #${this.reservationId}`,
        });
        this.containersTableMessage.push({
          severity: "info",
          summary: "Zaktualizowano",
          detail: `Zwolniono kontener ${this.lockedContainer.shift().storageNumber}`,
        });
      } else {
        throw new Error("Error saving storage container");
      }
    });
  }

  onRowEditCancel(container: any) {
    /*console.log("onRowEditCancel");
    console.log(container);*/
  }

  /*resetFunction(options: DropdownFilterOptions) {
    options.reset();
    this.filterValue = '';
  }

  customFilterFunction(event: KeyboardEvent, options: DropdownFilterOptions) {
    options.filter(event);
  }*/
  refreshAvailableContainers(event: any, container: any) {
    this.fetchData.getAvailableStorageContainers(this.reservationData)
      .subscribe({
        next: (availableStorages) => {
          this.lockedContainer = this.reservationData.storageContainers
            .filter((item: any) => item.id === container.id);
          if (availableStorages.status === HttpStatusCode.Ok) {
            this.availableContainers = [
              {
                label: "Zarezerwowane:",
                value: "reserved",
                items: this.lockedContainer,
              },
              { label: "Wolne:", value: "available", items: availableStorages.body },
            ];
          } else {
            throw new Error("Error fetching available storage containers");
          }
        },
        error: (error: any) => {
          console.error("Error fetching available storage containers:", error);
        },
      });
  }
}
