import { Component } from '@angular/core';

@Component({
  selector: 'creator-layout',
  templateUrl: './creator-layout.component.html',
  styleUrl: './creator-layout.component.scss'
})
export class CreatorLayoutComponent {

}
