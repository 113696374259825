import { AbstractControl } from "@angular/forms";

export class PeselValidator {
  static isValid(control: AbstractControl): {[key: string]: any} | null  {
    const peselValidator = new PeselValidator();
    return peselValidator.isPesel(control.value) ? null : { 'invalidPesel': true };
  }

  private isPesel(pesel: string): boolean {

    if (!/^[0-9]{11}$/.test(pesel) || pesel === "12345678910") {
      return false;
    }

    let weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    let sum = 0;
    for (let i = 0; i < weights.length; i++) {
      sum += parseInt(pesel[i]) * weights[i];
    }
    sum %= 10;
    let checkDigit = (10 - sum) % 10;
    return checkDigit === parseInt(pesel[10]);
  }
}
