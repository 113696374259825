import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { AuthService } from "../services/auth/auth.service";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'], // Zmieniono na 'styleUrls'
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent {

  showForgotPassword: boolean = false;
  localForm: FormGroup;
  localFormSentinel: any = {
    submitted: false,
  };
  resetPasswordForm: FormGroup;
  resetPasswordFormSentinel: any = {
    submitted: false,
  };
  @ViewChild('resetForm') ngForm!: NgForm; // Usunięto inicjalizację
  loadingResetPassword: boolean = false;

  constructor(
    private readonly authService: AuthService,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.localForm = this.fb.group({
      login: ["", [Validators.required, Validators.minLength(3)]],
      password: ["", [Validators.required, Validators.minLength(3)]],
    });
    this.resetPasswordForm = this.fb.group({
      emailReset: ["", [Validators.required, Validators.email]],
    });
  }

  localLogin() {
    console.log("Local login");
    this.localFormSentinel.submitted = true;

    this.authService.login(
      this.localForm.controls.login.value,
      this.localForm.controls.password.value
    ).subscribe((response: any) => {
      if (response.status === 404) {
        if (response.error) {
          this.localForm.setErrors({
            message: response.error.message
          });
          return;
        }
      }

      if (response.status === 400) {
        if (Object.keys(response.body.error.validatorsError).length > 0) {
          for (const field in response.body.error.validatorsError) {
            this.localForm.controls[field].setErrors({ ...response.body.error.validatorsError[field] });
          }
          return;
        }
      }

      if (response.status === 200) {
        console.log("Response: ", response);
        if (response.body.userType === "admin") {
          console.log("Admin login");
          this.router.navigate(["/panel"]).then(nav => {
            console.log('Navigation to /panel successful:', nav);
          }, err => {
            console.log('Navigation to /panel failed:', err);
          });
        } else if (response.body.userType === "customer") {
          console.log("Client login");
          this.router.navigate(["/client"]).then(nav => {
            console.log('Navigation to /client successful:', nav);
          }, err => {
            console.log('Navigation to /client failed:', err);
          });
        }
      }
    });
  }

  googleLogin() {
    console.log("Google login");
  }

  facebookLogin() {
    console.log("Facebook login");
  }

  twitterLogin() {
    console.log("Twitter login");
  }

  resetPassword() {
    console.log("Forgot password");
    this.authService.beginResetPassword({
      email: this.resetPasswordForm.controls.emailReset.value
    }).subscribe((response: any) => {
      if (response.status === 200) {
        this.showForgotPassword = false;
      }
      this.loadingResetPassword = false;
    });
  }

  register() {
    console.log("Register");
  }

}
