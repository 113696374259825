import { Component, OnInit } from "@angular/core";
import { Table } from "primeng/table";
import { FetchDataService } from "../../shared/data/services/fetch-data.service";

@Component({
  selector: 'clients',
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss'
})
export class ClientsComponent implements OnInit {
  loading: boolean = true;
  clients: any[] = [];

  selectedClients: any[] = [];
  statuses?: any[];
  filteredStatuses: any[] = [];
  clientTypes: any[] = [];

  constructor(private fetchDataService: FetchDataService) { }

  ngOnInit() {

    this.fetchDataService.getClients().then((clients) => {
      this.clients = clients;
      this.loading = false;
    });

    this.statuses = [
      { label: 'Aktywny', value: 'active' },
      { label: 'Nieaktywny', value: 'inactive' },
      { label: 'Zadłużony', value: 'indebted' },
    ];
    this.clientTypes = [
      { label: 'Osoba', value: 'person' },
      { label: 'Firma', value: 'company' },
    ];

  }

  clear(table: Table) {
    table.clear();
  }

  searchInput(dt: Table, event: any) {
    // console.log(event);
    dt.filterGlobal(event.target.value, 'contains');
  }

  getSeverity(status: any) {
    switch (status.toLowerCase()) {
      case 'active':
        return 'success';
      case 'indebted':
        return 'danger';
      case 'inactive':
        return 'secondary';
      case 'open':
        return 'contrast';
      default:
        return undefined;
    }
  }

  getSeverityLabel(reservationStatus: string) {
    return this.statuses?.filter((status) => status.value === reservationStatus)[0].label;
  }
}
