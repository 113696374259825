<div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12">
      <p-table #dt1 [value]="reservations" [scrollable]="true" scrollHeight="64vh" [tableStyle]="{'min-width': '50rem'}"
        dataKey="id" [rows]="10" [showCurrentPageReport]="false" [rowsPerPageOptions]="[5, 10, 15, 25, 50, 100]" [loading]="loading"
        [paginator]="true" [showJumpToPageDropdown]="true" paginatorLocale="pl"
        currentPageReportTemplate="Wiersze: {first} - {last} / {totalRecords}, Strona: {currentPage} / {totalPages}"
        [globalFilterFields]="['reservationNumber', 'name', 'location.name', 'country.name', 'representative.name', 'date', 'balance', 'status']" sortMode="multiple"
               [(selection)]="selectedCustomers" styleClass="p-datatable-striped"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-row justify-content-around">
            <!-- <div class="flex align-items-center justify-content-center">
              <div *ngIf="selectedCustomers !== undefined" class="grid align-items-center justify-content-center">
                <div class="col-8">
                  <button pButton label="Zmień statusy" class="p-button-outlined p-button-info" icon="fa-solid fa-pen-to-square"></button>
                </div>
                <div class="col-4">
                  <button pButton label="Usuń" class="p-button-outlined p-button-danger" icon="fa-regular fa-trash-can"></button>
                </div>
              </div>
            </div> -->
            <div class="flex align-items-center justify-content-between">
              <span class="p-input-icon-left mr-1">
                <i class="fa-solid fa-magnifying-glass"></i>
                <input pInputText type="text" class="w-full" (input)="searchInput(dt1, $event);" placeholder="Wyszukaj rezerwację..." />
              </span>
              <button pButton label="Wyczyść filtry" class="p-button-outlined ml-2" icon="fa-solid fa-filter-circle-xmark" (click)="clear(dt1)"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem;">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th style="min-width:12rem;" pSortableColumn="reservationNumber">
              <div class="flex align-items-center">
                # Rezerwacji
                <p-sortIcon class="ml-1" field="reservationNumber"></p-sortIcon>
                <p-columnFilter field="reservationNumber" display="menu" />
              </div>
            </th>
            <th style="min-width:15rem;" pSortableColumn="location.name">
              <div class="flex align-items-center">
                Lokalizacja
                <p-sortIcon class="mx-1" field="location.name"></p-sortIcon>
                <p-columnFilter field="location.name" matchMode="in" display="menu" [showMatchModes]="false"
                                [showOperator]="false"
                                [showAddButton]="false">
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">Filtruj lokalizację</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-filter="filterCallback">
                    <p-multiSelect [(ngModel)]="filteredLocation" [options]="locationList" placeholder="Wybierz lokalizacje..."
                                   (onChange)="filter($event.value);"
                                   optionLabel="name" optionValue="name">
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <span class="ml-1 mt-1">{{ option.name }}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem;" pSortableColumn="reservationStatus">
              <div class="flex align-items-center">
                Status
                <p-sortIcon field="reservationStatus"></p-sortIcon>
                <p-columnFilter field="reservationStatus" matchMode="in" display="menu"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false"
                >
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">Filtruj statusy</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-filter="filterCallback">
                    <p-multiSelect [(ngModel)]="filteredStatuses" [options]="statuses" optionLabel="label" optionValue="value"
                                   placeholder="Wybierz statusy..." display="comma" (onChange)="filter($event.value);"
                                   [showClear]="true" [displaySelectedLabel]="true" [maxSelectedLabels]="null"
                     >
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <p-tag [value]="option.label" [severity]="getSeverity(option.value)" />
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th style="min-width:13rem" pSortableColumn="reservationDate">
              <div class="flex align-items-center justify-content-around">
                Data złożenia
                <p-sortIcon field="reservationDate"></p-sortIcon>
                <p-columnFilter type="date" field="reservationDate" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem" pSortableColumn="date">
              <div class="flex align-items-center justify-content-around">
                Wynajem od
                <p-sortIcon field="date"></p-sortIcon>
                <p-columnFilter type="date" field="date" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:10rem" pSortableColumn="rentalPeriod">
              <div class="flex align-items-center justify-content-around">
                Długość wynajmu
                <p-sortIcon field="rentalPeriod"></p-sortIcon>
                <p-columnFilter type="number" field="rentalPeriod" display="menu"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:12rem" pSortableColumn="balance">
              <div class="flex align-items-center justify-content-around">
                Wartość rezerwacji (brutto)
                <p-sortIcon field="balance"></p-sortIcon>
                <p-columnFilter type="numeric" field="balance" display="menu" currency="PLN"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:12rem" pSortableColumn="totalCommission">
              <div class="flex align-items-center justify-content-around">
                Naliczony rabat (brutto)
                <p-sortIcon field="totalCommission"></p-sortIcon>
                <p-columnFilter type="numeric" field="totalCommission" display="menu" currency="PLN"></p-columnFilter>
              </div>
            </th>
            <!--<th style="min-width:12rem" pSortableColumn="status">
              <div class="flex align-items-center justify-content-around">
                Status
                <p-sortIcon field="status"></p-sortIcon>
                <p-columnFilter field="status" matchMode="equals" display="toolbox">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any">
                      <ng-template let-option pTemplate="item">
                        <p-tag [value]="option.value" [severity]="getSeverity(option.label)"></p-tag>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>-->
            <!--<th style="min-width:12rem" pSortableColumn="activity">
              <div class="flex align-items-center justify-content-around">
                Activity
                <p-sortIcon field="activity"></p-sortIcon>
                <p-columnFilter field="activity" matchMode="between" display="toolbox" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="filter" let-filter="filterCallback">
                    <p-slider [ngModel]="activityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                    <div class="flex align-items-center justify-content-between px-2">
                      <span>{{ activityValues[0] }}</span>
                      <span>{{ activityValues[1] }}</span>
                    </div>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>-->
            <!--<th style="width: 8rem" pSortableColumn="verified">
              <div class="flex align-items-center justify-content-around">
                Verified
                <p-sortIcon field="verified"></p-sortIcon>
                <p-columnFilter type="boolean" field="verified" display="toolbox"></p-columnFilter>
              </div>
            </th>-->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-reservation let-rowIndex="rowIndex">
          <tr [pSelectableRow]="reservation" [pSelectableRowIndex]="rowIndex" (click)="openRow($event, reservation.id);">
            <td>
              <p-tableCheckbox [value]="reservation"></p-tableCheckbox>
            </td>
            <td>
              {{ reservation.reservationNumber }}
            </td>
            <td>
              {{ reservation.location.name }}
            </td>
            <td>
              <p-tag [value]="getSeverityLabel(reservation.reservationStatus)" [severity]="getSeverity(reservation.reservationStatus)">
              </p-tag>
            </td>
            <td>
              {{ reservation.reservationDate | date: 'dd.MM.yyyy HH:mm:ss' }}
            </td>
            <td>
              {{ reservation.startDate | date: 'dd.MM.yyyy' }}
            </td>
            <td>
              {{ reservation.rentalPeriod }}
            </td>
            <td class="">
              <span class="mr-2">{{ parseFloat(reservation.totalBruttoPrice) + parseFloat(reservation.totalCommission) | currency: 'PLN' }}</span>
            </td>
            <td class="">
              <span class="mr-2">{{ parseFloat(reservation.totalCommission) | currency: 'PLN' }}</span>
            </td>
            <!--<td>
              <p-progressBar [value]="reservation.rentalPeriod" [showValue]="false"></p-progressBar>
            </td>-->
            <!--<td class="text-center">
              <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': true, 'text-red-500 pi-times-circle': false }"></i>
            </td>-->
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">Brak rezerwacji.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
