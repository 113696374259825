import { Component } from '@angular/core';

@Component({
  selector: 'storage-details',
  templateUrl: './storage-details.component.html',
  styleUrl: './storage-details.component.scss'
})
export class StorageDetailsComponent {

}
