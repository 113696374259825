import { Component, OnInit } from "@angular/core";
import { FetchDataService } from "../../../shared/data/services/fetch-data.service";
import { ActivatedRoute } from "@angular/router";
import { MenuItem } from "primeng/api";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

interface EventItem {
  status?: string;
  date?: string;
  icon?: string;
  color?: string;
  image?: string;
}

@Component({
  selector: 'client-details',
  templateUrl: './client-details.component.html',
  styleUrl: './client-details.component.scss'
})
export class ClientDetailsComponent implements OnInit {
  client: any = {};
  toolbox: MenuItem[] | undefined;
  events: EventItem[] | undefined;
  messages: any[] = [];
  clientForm: FormGroup;

  constructor(
    private readonly fetchDataService: FetchDataService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.clientForm = this.fb.group({
      name: [{ value: "", disabled: false }, [Validators.required]],
      subject: [{ value: "", disabled: false }, [Validators.required]],
      hasPESEL: [{ value: "", disabled: false }, [Validators.required]],
      status: [{ value: "", disabled: false }, [Validators.required]],
      userRegistrationDate: [{ value: "", disabled: false }, [Validators.required]],
      email: [{ value: "", disabled: false }, [Validators.required]],
      phone: [{ value: "", disabled: false }, [Validators.required]]
    });

    this.route.params.subscribe(params => {
      this.fetchDataService.getClient(params.id).then((client) => {
        this.client = client;
        console.log("client");
        console.log(client);
      });
    });
  }

  ngOnInit() {

    this.events = [
      { status: "Ordered", date: "15/10/2020 10:30", icon: "pi pi-shopping-reservationDetails", color: "#9C27B0", image: "game-controller.jpg" },
      { status: "Processing", date: "15/10/2020 14:00", icon: "pi pi-cog", color: "#673AB7" },
      { status: "Shipped", date: "15/10/2020 16:15", icon: "pi pi-shopping-reservationDetails", color: "#FF9800" },
      { status: "Delivered", date: "16/10/2020 10:00", icon: "pi pi-check", color: "#607D8B" },
    ];

    this.toolbox = [];
  }

  onSubmitDataTab() {
    console.log("Data tab submitted");
  }
}
