import { Component, OnInit } from "@angular/core";

import { ContractLengthType } from "../../shared/data/models/contract-length-type";

import dayjs from "dayjs";
import { CreatorService } from "../../shared/data/services/creator.service";
import { MenuItem, MessageService } from "primeng/api";
import { StorageContainerGroupPrice } from "../../shared/data/models/storage-container-group-price";
import { CartItem } from "../../shared/data/models/cart-item";
import { FetchDataService } from "../../shared/data/services/fetch-data.service";
import { InputNumberInputEvent } from "primeng/inputnumber";
import { Location } from "../../shared/data/models/location";
import { Router } from "@angular/router";
import { HttpStatusCode } from "@angular/common/http";

@Component({
  selector: "booking",
  templateUrl: "./booking.component.html",
  styleUrl: "./booking.component.scss",
  providers: [MessageService],
})
export class BookingComponent implements OnInit {
  minDate: Date;
  maxDate: Date;
  discountCode: string;
  validCode?: boolean | null;
  rentalPeriod: number;
  typeSubscription: string;
  stateValue: number;
  stateOptions: ContractLengthType[] = [
    { id: 1, label: "Czas określony" },
    { id: 2, label: "Czas nieokreślony" },
  ];
  assortment: StorageContainerGroupPrice[];
  reservation: any = {};
  reservationDetails: CartItem[] = [];
  sortItems: MenuItem[] = [
    {
      label: "Powierzchnia",
    },
    {
      label: "Cena",
    },
  ];
  prices: any[] = [
    { id: 1, range: "0 - 100zł" },
    { id: 2, range: "101 - 200zł" },
    { id: 3, range: "201 - 300zł" },
    { id: 4, range: "301 - 400zł" },
  ];
  daysoff = [
    new Date("2024-01-01"),
    new Date("2024-01-06"),
    new Date("2024-05-01"),
    new Date("2024-05-03"),
    new Date("2024-05-30"),
    new Date("2024-08-15"),
    new Date("2024-11-01"),
    new Date("2024-11-11"),
    new Date("2024-12-25"),
    new Date("2024-12-26"),
  ];
  selectedLocation: Location = new Location();
  selectedPrice: any | null = null;
  optionsPrice: any;
  lock: boolean = false;
  reservationStartDate!: Date;
  protected readonly Math = Math;
  protected readonly console = console;
  protected readonly dayjs = dayjs;

  constructor(private router: Router, protected creatorService: CreatorService, private fetchData: FetchDataService, private messageService: MessageService) {
    this.minDate = this.validateDay(dayjs().add(1, "d")).toDate();
    this.maxDate = this.countMaxDate();
    this.discountCode = "";
    this.validCode = null;
    this.stateValue = 1;
    this.typeSubscription = "";
    this.rentalPeriod = 1;
    this.optionsPrice = {
      "monthly": 0,
      "all": 0,
    };
    this.assortment = [];

    this.reservation = this.creatorService.getSessionStorageItem("reservationSession", {});
    this.reservationDetails = this.creatorService.getSessionStorageItem("reservationDetails", []);
    this.rentalPeriod = this.creatorService.getSessionStorageItem("rentalPeriod", 1);
    this.selectedLocation = (this.creatorService.getSessionStorageItem("selectedLocation") as Location);
  }

  ngOnInit() {
    // ToDo: Sprawdzać na wejściu, czy lokalizacja ma wolne kontenery.
    this.calculatePrices();
    this.lockVerification();
  }

  countMaxDate() {
    let maxDate = dayjs(this.minDate).add(14, "d").toDate();
    while ([0, 6].includes(maxDate.getDay())) {
      maxDate = dayjs(maxDate).subtract(1, "d").toDate();
    }
    for (const dayoff of this.daysoff) {
      if (dayjs(dayoff).add(1, "d").toDate().getDay() == 5) {
        this.daysoff.push(dayjs(dayoff).add(1, "d").toDate());
        if (dayjs(dayoff).add(1, "d").toDate().toLocaleDateString() == maxDate.toLocaleDateString()) {
          maxDate = dayjs(dayoff).subtract(1, "d").toDate();
        }
      }
    }
    while (this.daysoff.includes(maxDate)) {
      maxDate = dayjs(maxDate).subtract(1, "d").toDate();
    }
    return maxDate;
  }

  /**
   * optionClick
   */
  public optionClick(event: any) {
    this.stateValue = event.option.id;
    // console.log(this.stateValue);

  }

  public addToReservation(event: any, addDetail: StorageContainerGroupPrice) {
    let sentinel = this.reservationDetails.map(e => e.locationStoragesGroup?.id).indexOf(addDetail.id);
    const reservationSession = this.creatorService.getSessionStorageItem("reservationSession", []);

    console.log("addToReservation");
    console.log(addDetail);
    console.log(this.reservationDetails);
    console.log(sentinel);

    if (sentinel != -1) {
      this.reservationDetails[sentinel].quantity++;
      // Todo: dodać metodę HTTP, np. updateCart -> zwiększenie `quantity` elementu koszyka.
      this.creatorService.updateReservationDetails(this.reservationDetails[sentinel]);
    } else {
      this.creatorService.addToReservation({
        reservation: reservationSession,
        quantityReserved: 1,
        addDetail,
      }).subscribe({
        next: (response: any) => {
          if (response.status == HttpStatusCode.Created || response.status == HttpStatusCode.Ok) {
            this.reservationDetails.push({
              id: response.body.id,
              locationStoragesGroup: addDetail,
              quantity: 1,
              discountValue: 0,
            });
            this.creatorService.setSessionStorageItem("reservationDetails", this.reservationDetails);
            this.calculatePrices();
            this.lockVerification();
          } else {
            console.error(response);
          }
        },
      });
    }
  }

  public removeFromCart(event: any, index: number, item: CartItem) {
    console.log("removeFromCart");
    console.log(item);
    this.creatorService.removeFromCart({
      reservation: this.reservation,
      itemId: item.locationStoragesGroup?.id,
    }).subscribe({
      next: (response: any) => {
        if (response.status == HttpStatusCode.Ok) {
          this.reservationDetails = [
            ...this.reservationDetails.slice(0, index),
            ...this.reservationDetails.slice(index + 1),
          ];
          this.creatorService.setSessionStorageItem("reservationDetails", this.reservationDetails);
          this.calculatePrices();
          this.lockVerification();
        }
      },
    });
  }

  public clearCart(event: any) {
    this.creatorService.removeAll({
      reservation: this.reservation,
    }).subscribe({
      next: (response: any) => {
        if (response.status == HttpStatusCode.Ok) {
          this.reservationDetails = [];
          this.creatorService.setSessionStorageItem("reservationDetails", this.reservationDetails);
          this.calculatePrices();
          this.lockVerification();
        }
      },
    });
  }

  public discountBtnClick(event: any) {

    if (!this.validCode) {
      this.fetchData.validateDiscountCode(this.discountCode)
        .then((result) => {
          console.log(this.discountCode);
          console.log("result discountCode:");
          console.log(result);

          this.validCode = result.valid;
          if (!result.valid) {
            this.discountCode = "";
          }

        }).catch((e) => console.log(e));
    } else {
      this.discountCode = "";
      this.validCode = false;
    }
  }

  validateDay(date: dayjs.Dayjs) {
    // console.log(date.get('d'));
    // console.log(date.get('d') == 0);
    if (date.get("d") == 0) {
      date = date.add(1, "d");
    } else if (date.get("d") == 6) {
      date = date.add(2, "d");
    }
    return date;
  }

  calculateCart() {
    const sum = this.reservationDetails.reduce((acc, item) => {
      const price = item.locationStoragesGroup?.nettoUnitPromoPrice ?? item.locationStoragesGroup?.nettoUnitPrice;
      return acc + item.quantity * price;
    }, 0);
    return sum * this.rentalPeriod;
  }

  applyDiscount(value: number) {
    return this.typeSubscription === "all" ? value + this.countDiscount(value) : value;
  }

  countDiscount(value: number) {
    if (this.typeSubscription === "all") {
      if (this.rentalPeriod > 11) return value * -0.2;
      if (this.rentalPeriod > 5) return value * -0.1;
      if (this.rentalPeriod > 2) return value * -0.05;
    }
    return 0;
  }

  calculatePrices() {
    this.optionsPrice = {
      "monthly": (this.calculateCart() / this.rentalPeriod),
      "all": this.calculateCart(),
    };
  }

  lockVerification() {
    let
      maxCartUnits = 5, // @ToDo: jako właściwość lokalizacji, dać możliwość różnych limitów na lokalizacjach.
      cartUnits = 0
    ;

    this.reservationDetails.forEach((item) => {
      console.log("forEach");
      cartUnits += item.quantity;
    });

    this.lock = cartUnits > maxCartUnits;
    if (this.lock) {
      this.messageService.add({
        key: "lockCart",
        severity: "warn",
        summary: "Uwaga !!!",
        detail: `Chcesz zarezerwować ponad <span class="font-bold">5</span> kontenerów? <br/>Skontaktuj się z Nami:`,
      });
    }

  }

  modifyReservation($event: InputNumberInputEvent, item: CartItem) {
    console.log("$event");
    console.log($event);
    console.log(item);
    const reservation = this.creatorService.getSessionStorageItem("reservationSession", {});
    this.creatorService.updateReservationDetails({ reservation, item })
      .subscribe({
        next: (response: any) => {
          if (response.status == HttpStatusCode.Ok) {
            this.creatorService.setSessionStorageItem("reservationDetails", this.reservationDetails);
            this.calculatePrices();
            this.lockVerification();
          }
        },
      });

  }

  loadAssortment() {
    this.fetchData.getStoragesGroupsByLocation(this.selectedLocation.id).subscribe({
      next: (response: any) => {
        if (response.status == 200) {
          this.assortment = (response.body as StorageContainerGroupPrice[]);
        } else {
          console.warn(response);
        }
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }

  setBeginBooking() {
    console.log("setBeginBooking");
    console.log(dayjs(this.reservationStartDate).format("YYYY-MM-DD"));
    console.log(this.rentalPeriod);
  }

  goToForm($event: MouseEvent) {
    console.log("goToForm");
    console.log(this);
    this.creatorService.saveReservation({
      reservation: this.creatorService.getSessionStorageItem("reservationSession"),
      rentalPeriod: this.rentalPeriod,
      typeSubscription: this.typeSubscription,
      reservationStartDate: this.reservationStartDate,
    }).subscribe({
      next: (response: any) => {
        if (response.status == HttpStatusCode.Ok) {
          this.creatorService.setSessionStorageItem("reservationSession", response.body.reservation);
          this.router.navigate(["/creator/orderForm"]);
        } else {
          console.warn(response);
        }
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }
}
