import { Component } from '@angular/core';

@Component({
  selector: 'steps-router',
  standalone: true,
  imports: [],
  templateUrl: './steps-router.component.html',
  styleUrl: './steps-router.component.scss'
})
export class StepsRouterComponent {
  // ToDo: mapowanie, inna możliwość zaznaczania.
  routeMap = [
    { path: '/creator', active: true },
    { path: '/creator/booking', active: false },
    { path: '/creator/orderForm', active: false },
    { path: '/creator/summary', active: false },
  ]
}
