import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CreatorService } from "../../shared/data/services/creator.service";
import { Location } from "../../shared/data/models/location";
import { FetchDataService } from "../../shared/data/services/fetch-data.service";
import { Address } from "../../shared/data/models/address";

@Component({
  selector: "creator-home",
  templateUrl: "./creator-home.component.html",
  styleUrl: "./creator-home.component.scss",
})
export class CreatorHomeComponent implements OnInit {
  selectedLocation: Location | null = null;
  currentView: string;
  locations!: Location[];
  locked = false;

  constructor(
    private router: Router,
    protected readonly creatorService: CreatorService,
    private readonly fetchData: FetchDataService) {
    this.currentView = this.router.url;
  }

  ngOnInit(): void {

    if (!this.creatorService.getSessionStorageItem("selectedLocation")) {
      this.creatorService.setSessionStorageItem("selectedLocation", null);
    } else {
      this.selectedLocation = (this.creatorService.getSessionStorageItem("selectedLocation") as Location);
    }
    console.log("selectedLocation");
    console.log(this.selectedLocation);

    this.fetchData.getLocations().subscribe({
      next: (response: any) => {
        if (response.status == 200) {
          this.locations = response.body;
        } else {
          console.warn("Błąd: locations");
          console.error(response);
        }
      },
      error: (error: any) => {
        console.error(error);
      },
    });

  }

  protected readonly Address = Address;

  startCart(): void {
    // ToDo: sprawdzić, czy lokalizacja ma wolne kontenery.
    // ToDo: sprawdzić po powrocie z następnego kroku, czy można przejść dalej.
    const reservationSession = this.creatorService.getSessionStorageItem("reservationSession", null);
    console.log(reservationSession);
    if (!reservationSession && this.selectedLocation && !this.locked) {
      /*
      this.creatorService
        .createCart(this.selectedLocation)
        .subscribe((response: any) => {
          if (response.response.statusCode == 201) {
            this.creatorService.setSessionStorageItem("reservationSession", response.data);
            this.router.navigate(["/creator/booking"]);
          } else {
            console.warn("Błąd: reservationSession");
            console.error(response);
          }
        });*/

      this.creatorService.startReservation(this.selectedLocation).subscribe({
        next: (response: any) => {
          if (response.status == 201) {
            this.creatorService.setSessionStorageItem("reservationSession", response.body);
            this.router.navigate(["/creator/booking"]);
          } else {
            console.warn("Błąd: reservationSession");
            console.error(response);
          }
        },
      });

    } else if (reservationSession && this.selectedLocation) {
      /*this.creatorService.updateCart({
        locationId: this.selectedLocation.id,
      }).subscribe((response: any) => {
        if (response.response.statusCode == 200) {
          this.creatorService.setSessionStorageItem("reservationSession", response.data);
          this.router.navigate(["/creator/booking"]);
        } else {
          console.warn("Błąd: reservationSession");
          console.error(response);
        }
      });*/
    }
  }

  selectLocation($event: MouseEvent, index: number) {
    this.selectedLocation = this.locations[index];
    this.creatorService.setSessionStorageItem("selectedLocation", null);
    if (this.locations[index].containersAvailableCount > 0) {
      this.creatorService.setSessionStorageItem("selectedLocation", this.locations[index]);
    } else {
      this.locked = true;
      this.creatorService.setSessionStorageItem("selectedLocation", null);
    }

    if (this.creatorService.getSessionStorageItem("reservationSession")) {
      this.creatorService.setSessionStorageItem("reservationSession", {}); // ToDo: update reservation.
    }
  }
}
