import { Injectable } from "@angular/core";
import { DiscountCode, discountType } from "../models/discount-code";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FetchDataService {
  discountCodes: DiscountCode[] = [
    {
      id: "a089e343-851c-425a-b077-3a1f2a651ec6",
      discountCode: "dGVzdGdncnJl",
      typeDiscount: discountType.amount,
      discountValue: 200
    }
  ];

  clients: any[] = [
    {
      id: '8b740274-a274-40d7-b13d-b19a5b9d50c7',
      name: 'Jan Kowalski',
      subject: 'person',
      status: 'active',
      userRegistrationDate: '2024-10-11',
      email: '',
      phone: ''
    },
    {
      id: '34eeab02-4c11-4cb6-8228-5b3eb3f266d2',
      name: 'Adam Nowak',
      subject: 'person',
      status: 'indebted',
      userRegistrationDate: '2024-12-01',
      email: '',
      phone: ''
    },
    {
      id: 'f0b1c6f5-1b5d-4d1b-8d2e-0e6b0d3f1b9c',
      name: 'Anna Wiśniewska',
      subject: 'person',
      status: 'inactive',
      userRegistrationDate: null,
      email: '',
      phone: ''
    },
    {
      id: 'f0b1c6f5-1b5d-4d1b-8d2e-0e6b0d3f1b9c',
      name: 'Budarnik Sp. z o.o.',
      subject: 'company',
      status: 'active',
      userRegistrationDate: '2024-10-11',
      email: '',
      phone: ''
    }
  ];

  constructor(private readonly http: HttpClient) { }

  getLocations() {
    return this.http.get("/api/locations", {
      withCredentials: true,
      observe: 'response'
    });
  }

  getLocation(id: string) {
    return this.http.get(`/api/locations/${id}`, {
      withCredentials: true,
      observe: 'response'
    });
  }

  getLocationContainers(id: string) {
    return this.http.get(`/api/locations/${id}/storageGroups`, {
      withCredentials: true,
      observe: 'response'
    });
  }

  getStorageContainers() {
    return this.http.get("/api/storageContainers", {
      withCredentials: true,
      observe: 'response'
    });
  }

  getStorageContainer(id: number) {
    return this.http.get(`/api/storageContainers/${id}`, {
      withCredentials: true,
      observe: 'response'
    });
  }

  getStoragesGroupsByLocation(locationId: number) {
    return this.http.get(`/api/storageContainers/groups/${locationId}`, {
      withCredentials: true,
      observe: 'response'
    });
  }

  getStorageStatuses() {
    return Promise.resolve([
      { name: "Dostępny", value: 'available', backgroundColor: "#689f38"},
      { name: "Zarezerwowany", value: 'reserved', backgroundColor: "#fbc02d"},
      { name: "Zamówiony", value: 'booked', backgroundColor: "#2196f3"},
      { name: "Wynajęty", value: 'rented', backgroundColor: "#d32f2f"},
      { name: "Serwis", value: 'serviced', backgroundColor: "#64748b"},
      { name: "Wyłączony", value: 'disabled', backgroundColor: "#212121"},
    ]);
  }

  getStorageGroups() {
    return Promise.resolve([
      { id: 1, name: "Magazyn 7m2"},
      { id: 2, name: "Magazyn 14m2"},
      { id: 3, name: "Magazyn 29m2"},
    ]);
  }

  getReservations() {
    return this.http.get("/api/reservation", {
      withCredentials: true,
      observe: 'response'
    });
  }

  getReservation(reservationId: string): Observable<any> {
    return this.http.get(`/api/reservation/${reservationId}`, {
      withCredentials: true,
      observe: 'response'
    });
  }

  getAvailableStorageContainers(reservationData: any): Observable<any> {
    return this.http.get(`/api/storageContainers?location.id=${reservationData.location.id}&status=available`, {
      withCredentials: true,
      observe: 'response'
    });
  }

  validateDiscountCode(discountCode: string) {
    let rows = this.discountCodes.filter((item) => item.discountCode == discountCode);
    let row = rows.shift();
    return Promise.resolve(
      row ? {valid: true, discount: row} : {valid: false, discount: null}
    );
  }

  getClients() {
    return Promise.resolve(this.clients);
  }

  getClient(id: string) {
    return Promise.resolve(this.clients.find((client) => client.id === id));
  }
}
