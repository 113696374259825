<div class="text-center px-2 py-4 md:px-6 lg:px-8">
  <steps-router></steps-router>

  <div class="grid">
    <div class="col-12 px-0 pt-4 pb-6">
      @if (this.reservation.paymentUrl == null) {
        <div class="flex align-items-start px-4 py-6 bg-yellow-100 border-round border-1 border-yellow-300">
          <i class="fa-solid fa-circle-info text-yellow-900 text-xl ml-8"></i>
          <div class="mr-3 w-full">
            <div class="text-yellow-900 font-medium text-2xl mb-6 line-height-1">Uwaga</div>
            <span class="m-0 p-0 text-yellow-700 mb-5 line-height-3">
              Prosimy o wykonaniu przelewu: <br />
              <ul class="m-0 p-0 text-yellow-700 ml-3">
                <li class="p-1">
                  Tytuł przelewu: <span class="font-semibold">Rezerwacja #{{ this.reservation.reservationNumber }}</span>
                </li>
                <li class="p-1">
                  Nr konta: <span class="font-semibold">{{ this.reservation.location.bankAccountNumber }}</span> <br />
                </li>
                <li class="p-1">
                  Kwota przelewu: <span class="font-semibold">{{ this.reservation.totalBruttoPrice | currency:'PLN' }}</span>
                </li>
              </ul>
              <br/>
              <ul class="m-0 p-0 text-yellow-700 ml-3">
                <li class="">
                  Kwota netto: <span class="font-semibold">{{ this.reservation.totalNettoPrice | currency:'PLN' }}</span>
                </li>
                <li class="">
                  Kwota VAT: <span class="font-semibold">{{ (this.reservation.totalBruttoPrice - this.reservation.totalNettoPrice) | currency:'PLN' }}</span>
                </li>
              </ul>
              <br/>
              <ul class="m-0 p-0 text-yellow-700 ml-3">
                <li class="p-1">Wpłata musi zostać zaksięgowana na Naszym koncie <span class="font-semibold">w ciągu 5 dni</span> od złożenia rezerwacji.</li>
                <li class="p-1">W przypadku braku wpłaty, rezerwacja zostanie anulowana.</li>
              </ul>
              <br/>
              Po przesłaniu potwierdzeniu przelewu, Nas zespół zacznie realizację Twojej rezerwacji.
            </span>
            <p class="m-0 p-0 text-yellow-700 mb-3 line-height-3">
              Masz pytania? Potrzebujesz Naszego wsparcia? Skontaktuj się z nami:
            </p>
            <a
              class="font-medium text-blue-900 hover:text-blue-800 mr-4 cursor-pointer select-none transition-colors transition-duration-150"
              href="mailto:biuro@przechowalnia24.pl?subject=Status rezerwacji #{{ this.reservation.reservationNumber }}">Napisz e-mail: biuro&#64;przechowalnia24.pl</a>
            <a
              class="font-medium text-blue-900 hover:text-blue-800 cursor-pointer select-none transition-colors transition-duration-150"
              href="tel:+48883839389">Zadzwoń: +48 883 839 389</a>
          </div>
        </div>
      } @else if (this.reservationStatuses.awaiting_payment) {
        <div class="flex align-items-start p-4 bg-blue-100 border-round border-1 border-blue-300">
          <i class="fa-solid fa-circle-info text-blue-900 text-2xl mr-3"></i>
          <div class="mr-3 w-full">
            <div class="text-blue-900 font-medium text-xl mb-2 line-height-1">Informacja</div>
            <p class="m-0 p-0 text-blue-700 mb-3 line-height-3">
              Twoja rezerwacja oczekuje na dokonanie płatności. <br/>
              <a pButton label="Kliknij tutaj, aby opłacić rezerwację" class="p-button font-semibold no-underline mb-3" icon="fa-solid fa-up-right-from-square" iconPos="right"
                 href="{{ this.reservation.paymentUrl }}" target="_blank" rel="noopener noreferrer">
              </a><br/>
              Po dokonaniu płatności, rezerwacja zostanie zlecona do realizacji przez Nas zespół.
            <p class="m-0 p-0 text-blue-700 mb-3 line-height-3">
              Masz pytania? Potrzebujesz Naszego wsparcia? Skontaktuj się z nami:
            </p>
            <a
              class="font-medium text-blue-900 hover:text-blue-800 mr-4 cursor-pointer select-none transition-colors transition-duration-150"
              href="mailto:biuro@przechowalnia24.pl?subject=Status rezerwacji #{{ this.reservation.reservationNumber }}">Napisz e-mail: biuro&#64;przechowalnia24.pl</a>
            <a
              class="font-medium text-blue-900 hover:text-blue-800 cursor-pointer select-none transition-colors transition-duration-150"
              href="tel:+48883839389">Zadzwoń: +48 883 839 389</a>
          </div>
        </div>
      } @else {
        <div class="flex align-items-start p-4 bg-blue-100 border-round border-1 border-blue-300">
          <i class="fa-solid fa-circle-info text-blue-900 text-2xl mr-3"></i>
          <div class="mr-3 w-full">
            <div class="text-blue-900 font-medium text-xl mb-2 line-height-1">Informacja</div>
            <p class="m-0 p-0 text-blue-700 mb-3 line-height-3">Twoja rezerwacja jest przez Nas realizowana. Masz
              pytania? Skontaktuj się z nami:</p>
            <a
              class="font-medium text-blue-900 hover:text-blue-800 mr-4 cursor-pointer select-none transition-colors transition-duration-150"
              href="mailto:biuro@przechowalnia24.pl?subject=Status rezerwacji #{{ this.reservation.reservationNumber }}">Napisz e-mail: biuro&#64;przechowalnia24.pl</a>
            <a
              class="font-medium text-blue-900 hover:text-blue-800 cursor-pointer select-none transition-colors transition-duration-150"
              href="tel:+48883839389">Zadzwoń: +48 883 839 389</a>
          </div>
        </div>
      }
    </div>
  </div>

  <div class="grid">
    <div class="col-12">
      <div class="border-round surface-card p-5 shadow-2">
        <div class="flex flex-column lg:flex-row">
          <div class="flex flex-auto flex-column md:flex-row">
            <div
              class="border-bottom-1 pb-5 md:border-bottom-none md:border-right-1 surface-border flex flex-column justify-content-center md:justify-content-start"
            >
              <h2 class="w-full">Status rezerwacji</h2>
              <ul class="list-none md:pr-4 md:pl-0 lg:pr-6 lg:pl-1">
                <li class="h-6rem flex">
                  <div class="flex flex-column align-items-center">
                    <div class="border-circle flex-shrink-0" style="border: 3px solid; height:1.5rem; width: 1.5rem"
                       [ngClass]="{
                        'bg-primary': this.reservationStatuses.submitted,
                        'border-primary': this.reservationStatuses.submitted,
                        'surface-card': !this.reservationStatuses.submitted && this.reservationStatuses.open,
                        'border-300': !this.reservationStatuses.submitted,
                        'surface-300': !this.reservationStatuses.submitted
                      }"
                    ></div>
                    <div class="h-6rem" style="width:3px" [ngClass]="{
                        'bg-primary': this.reservationStatuses.submitted,
                        'surface-300': !this.reservationStatuses.submitted
                      }"></div>
                  </div>
                  <div class="flex flex-column ml-3">
                    <span class="font-medium text-900 mb-2">Złożona</span>
                    <span class="text-600"><i class="fa-solid fa-calendar-check"></i> {{ this.reservation.reservationDate | date:'dd.MM.yyyy HH:mm' }}</span>
                  </div>
                </li>
                <li class="h-6rem flex">
                  <div class="flex flex-column align-items-center">
                    <div class="border-circle flex-shrink-0" style="border: 3px solid; height:1.5rem; width: 1.5rem"
                         [ngClass]="{
                            'bg-primary': this.reservationStatuses.paid,
                            'border-primary': this.reservationStatuses.paid,
                            'surface-card': !this.reservationStatuses.paid && this.reservationStatuses.submitted,
                            'border-300': !this.reservationStatuses.paid,
                            'surface-300': !this.reservationStatuses.paid
                          }"
                    ></div>
                    <div class="h-6rem" style="width:3px"
                         [ngClass]="{
                          'bg-primary': this.reservationStatuses.paid,
                          'surface-300': !this.reservationStatuses.paid
                          }"
                    ></div>
                  </div>
                  <div class="flex flex-column ml-3">
                    <span class="font-medium text-900 mb-2">
                      @if (this.reservationStatuses.paid) {
                        Opłacone
                      } @else if (this.reservationStatuses.awaiting_payment) {
                        Oczekuje na płatność
                      } @else {
                        Płatność jest przetwarzana
                      }
                    </span>
                    @if (this.reservationStatuses.paid) {
                      <span class="text-600">
                        <i class="fa-solid fa-calendar-check"></i> {{ this.reservation.reservationDate | date:'dd.MM.yyyy HH:mm' }}
                      </span>
                    } @else if (this.reservationStatuses.awaiting_payment && this.reservation.paymentUrl) {
                      <a pButton label="Opłać" class="p-button font-semibold no-underline" icon="fa-solid fa-up-right-from-square" iconPos="right"
                         [href]="this.reservation.paymentUrl" target="_blank" rel="noopener noreferrer">
                      </a>
                    } @else {
                      <span class="text-600" [pTooltip]="tooltipPaymentProcess" tooltipPosition="bottom">
                        <i class="fa-solid fa-circle-info"></i> Ważne!
                      </span>
                      <ng-template #tooltipPaymentProcess>
                        <div class="flex align-items-center">
                          <span> W przypadku opłaty przelewem tradycyjny przetwarzanie potrwa min. 2 dni robocze. </span>
                        </div>
                      </ng-template>
                    }
                  </div>
                </li>
                <!-- <li class="h-6rem flex">
                  <div class="flex flex-column align-items-center">
                    <div class="bg-primary border-primary border-circle flex-shrink-0" style="border: 3px solid; height:1.5rem; width: 1.5rem"></div>
                    <div class="bg-primary h-6rem" style="width:3px"></div>
                  </div>
                  <div class="flex flex-column ml-3">
                    <span class="font-medium text-900 mb-2">Zarezerwowane</span>
                    <span class="text-600"><i class="fa-solid fa-calendar-check"></i> 22.02.2024 15:04</span>
                  </div>
                </li> -->
                <li class="h-6rem flex">
                  <div class="flex flex-column align-items-center">
                    <div class="border-circle flex-shrink-0" style="border: 3px solid; height:1.5rem; width: 1.5rem"
                         [ngClass]="{
                            'bg-primary': this.reservationStatuses.pending,
                            'border-primary': this.reservationStatuses.pending,
                            'surface-card': this.reservationStatuses.pending && !this.reservationStatuses.ready,
                            'border-300': !this.reservationStatuses.pending,
                            'surface-300': !this.reservationStatuses.pending
                          }"
                    ></div>
                    <div class="h-6rem" style="width:3px"
                         [ngClass]="{
                            'bg-primary': this.reservationStatuses.pending,
                            'surface-300': !this.reservationStatuses.pending
                          }"
                    ></div>
                  </div>
                  <div class="flex flex-column ml-3">
                    <span class="font-medium text-900 mb-2">Przygotowywanie</span>
                    <span class="text-600"><i class="fa-solid fa-calendar-day"></i> 23.02.2024</span>
                  </div>
                </li>
                <li class="h-6rem flex">
                  <div class="flex flex-column align-items-center">
                    <div class="border-circle flex-shrink-0" style="border: 3px solid; height:1.5rem; width: 1.5rem"
                         [ngClass]="{
                            'bg-primary': this.reservationStatuses.ready,
                            'border-primary': this.reservationStatuses.ready,
                            'surface-card': this.reservationStatuses.ready && !this.reservationStatuses.completed,
                            'border-300': !this.reservationStatuses.ready,
                            'surface-300': !this.reservationStatuses.ready
                          }"
                    ></div>
                    <div class="h-6rem" style="width:3px" [ngClass]="{
                            'bg-primary': this.reservationStatuses.ready,
                            'surface-300': !this.reservationStatuses.ready
                          }"
                    ></div>
                  </div>
                  <div class="flex flex-column ml-3">
                    <span class="font-medium text-900 mb-2">Gotowe</span>
                    <span class="text-600"><i class="fa-solid fa-calendar"></i> Zakł. 01.03.2024</span>
                  </div>
                </li>
                <li class="h-6rem flex">
                  <div class="flex flex-column align-items-center">
                    <div class="border-circle flex-shrink-0" style="border: 3px solid; height:1.5rem; width: 1.5rem" [ngClass]="{
                            'bg-primary': this.reservationStatuses.completed,
                            'border-primary': this.reservationStatuses.completed,
                            'surface-card': this.reservationStatuses.completed && !this.reservationStatuses.cancelled,
                            'border-300': !this.reservationStatuses.completed,
                            'surface-300': !this.reservationStatuses.completed
                          }"
                    ></div>
                    <!-- <div class="surface-300 h-6rem" style="width:3px"></div> -->
                  </div>
                  <div class="flex flex-column ml-3">
                    <span class="font-medium text-900 mb-2">Udostępnione</span>
                    <span class="text-600"><i class="fa-solid fa-calendar"></i> Zakł. 02.03.2024</span>
                  </div>
                </li>
              </ul>
            </div>
            <!-- Etap II: <div class="flex-auto md:pl-5 lg:pr-5 pt-5 md:pt-0 border-right-none lg:border-right-1 surface-border">-->
            <div class="flex-auto md:pl-5 lg:pr-5 pt-5 md:pt-0 surface-border">
              <div class="flex align-items-center justify-content-between my-5">
                <span class="font-medium text-2xl text-900">Rezerwacja #{{ this.reservation?.reservationNumber }}</span>
                <!-- <button pButton pRipple class="p-button-text font-medium">Invoice</button> -->
              </div>
              <div class="flex flex-column my-3">
                <!--<span class="text-xl font-medium mb-3 text-900">Rezerwujący</span>-->
                <div class="flex flex-row justify-content-between text-700">
                  <span class="mb-1">Wynajem od:</span>
                  <span class="mb-1 ml-1 font-semibold">{{ this.reservation?.startDate | date:'dd.MM.yyyy'}}</span>
                  <span class="mb-1">Wartość rezerwacji:</span>
                  <span class="mb-1 ml-1 font-semibold">{{ this.reservation?.totalBruttoPrice | currency:'PLN' }} brutto</span>
                  <!--<span class="mb-1">Celeste Slater</span>
                  <span class="mb-1">606-3727 Ullamcorper. Roseville NH 11523</span>
                  <span>(786) 713-8616</span>-->
                </div>
              </div>
              <div class="flex flex-column xl:flex-row mb-5">
                <section class="grid w-full">
                  @if (this.reservation.reservationDetails.length > 0) {
                    @for (item of this.reservation.reservationDetails; track $index) {
                      <div class="col-12">
                        <article class="flex flex-column md:flex-row w-full gap-3 p-3 border-round border-1 surface-border surface-card">
                          <div class="relative">
                            <img src="assets/images/blocks/hotels/h5.jpeg" alt="Image"
                                 class="border-round w-full h-full md:w-8rem md:h-8rem">
                            <p class="absolute px-2 py-1 border-round-lg text-sm font-normal text-white mt-0 mb-0"
                               style="background-color: rgba(255, 255, 255, 0.3); backdrop-filter: blur(10px); top: 3%; left: 3%;">
                              {{ item.nettoUnitPrice | currency:'PLN' }} netto / msc / szt.
                            </p>
                          </div>
                          <div class="flex flex-column w-full gap-3">
                            <div class="flex w-full justify-content-between align-items-center flex-wrap gap-3">
                              <p class="font-semibold text-lg mt-0 mb-0">{{ item.groupPrices.group.name }}</p>
                            </div>
                            <p class="font-normal text-lg text-600 mt-0 mb-0">{{ item.groupPrices.group.description }}</p>
                            <div class="flex flex-wrap gap-8 justify-content-end align-items-end md:h-2rem mt-auto">
                              <p class="text-base flex align-items-center text-900 mt-0 mb-1">
                                <i class="fa-solid fa-ruler-combined mr-2"></i>
                                <span class="font-medium">{{ item.groupPrices.group.size | number:'1.2' }} m2</span>
                              </p>
                              <p class="text-base flex align-items-center text-900 mt-0 mb-1">
                                <i class="fa-solid fa-cubes-stacked mr-2"></i>
                                <span class="font-medium">Sztuk: {{ item.quantityReserved }}</span>
                              </p>
                            </div>
                            <!-- <div class="flex">
                              <p class="font-semibold text-4xl text-900 mt-0 mb-2 mr-2">$510</p>
                              <span class="text-sm text-gray-500">$</span>
                              <p class="font-semibold text-4xl text-900 mt-0 mb-2 line-through text-gray-500">850</p>
                            </div> -->
                          </div>
                        </article>
                      </div>
                    }
                  } @else {
                    <div class="col-6 xl:col-12">
                      <p class="text-700">BŁĄD: Brak produktów w rezerwacji.</p>
                    </div>
                  }
                  <!--<div class="col-12 xl:col-12">
                    <article class="flex flex-column md:flex-row w-full gap-3 p-3 border-round border-1 surface-border surface-card">
                      <div class="relative">
                        <img src="https://blocks.primeng.org/assets/images/blocks/hotels/h5.jpeg" alt="Image"
                             class="border-round w-full h-full md:w-14rem md:h-14rem">
                        <p class="absolute px-2 py-1 border-round-lg text-sm font-normal text-white mt-0 mb-0"
                           style="background-color: rgba(255, 255, 255, 0.3); backdrop-filter: blur(10px); top: 3%; left: 3%;">
                          Superhost
                        </p>
                      </div>
                      <div class="flex flex-column w-full gap-3">
                        <div class="flex w-full justify-content-between align-items-center flex-wrap gap-3">
                          <p class="font-semibold text-lg mt-0 mb-0">Home in Amsterdam</p>
                        </div>
                        <p class="font-normal text-lg text-600 mt-0 mb-0">Large comfortable room in Sarphatistraat</p>
                        <div class="flex flex-wrap justify-content-between md:h-2rem mt-auto">
                          <p class="text-base flex align-items-center text-900 mt-0 mb-1">
                            <i class="pi pi-users mr-2"></i>
                            <span class="font-medium">2 Guests</span>
                          </p>
                          <p class="text-base flex align-items-center text-900 mt-0 mb-1">
                            <i class="pi pi-wifi mr-2"></i>
                            <span class="font-medium">1 GBit</span>
                          </p>
                          <p class="text-base flex align-items-center text-900 mt-0 mb-1">
                            <i class="pi pi-check-circle mr-2"></i>
                            <span class="font-medium">Verified</span>
                          </p>
                        </div>
                        &lt;!&ndash; <div class="flex">
                          <p class="font-semibold text-4xl text-900 mt-0 mb-2 mr-2">$510</p>
                          <span class="text-sm text-gray-500">$</span>
                          <p class="font-semibold text-4xl text-900 mt-0 mb-2 line-through text-gray-500">850</p>
                        </div> &ndash;&gt;
                      </div>
                    </article>
                  </div>-->
                </section>
              </div>
            </div>
          </div>
          <!-- Etap II:
          <div class="flex flex-column border-top-1 lg:border-top-none surface-border pl-0 pt-5 lg:pt-0 lg:pl-5 mt-5 lg:mt-0">
              <button pButton pRipple icon="fa-solid fa-paper-plane" label="Kontakt" class="p-button-outlined p-button-help mb-3"></button>
              <button pButton pRipple icon="fa-solid fa-file-invoice-dollar" label="Rachunek" class="p-button-outlined p-button-info"></button>
          </div>
          -->
        </div>
      </div>
    </div>
  </div>
</div>
