<div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12">
      <p-tabView styleClass="tabview-custom" [scrollable]="true" [autoHideButtons]="true">
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-regular fa-file-lines mr-2 text-xl"></i>
            <span>Dane</span>
          </ng-template>
          <div class="grid">
            <div class="col-12">
              <div class="px-1 py-3 md:px-2 lg:px-4">
                <header class="grid">
                  <div class="col-12">
                    <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
                      <div class="flex md:align-items-center md:justify-content-between flex-column md:flex-row pb-4 border-bottom-1 surface-border">
                        <div class="flex align-items-center">
                          <i class="fa-solid fa-user-large text-2xl mr-3 text-500"></i>
                          <span class="text-3xl font-medium text-900">{{ this.client.name }}</span>
                        </div>
                        <div class="mt-3 md:mt-0">
                          <!--<button pButton pRipple label="Compose" icon="pi pi-plus"></button>-->
                          <p-button class="p-button-outlined mr-3" pRipple (click)="toolboxMenu.toggle($event)" label="Narzędzia"
                                    icon="fa-solid fa-screwdriver-wrench" />
                          <p-menu #toolboxMenu [model]="toolbox" [popup]="true" appendTo="body"
                                  styleClass="w-20rem px-1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </header>
                <div class="surface-card p-4 shadow-2 border-round">
                  <div>
                    <form class="grid formgrid p-fluid" [formGroup]="clientForm" (ngSubmit)="onSubmitDataTab();">
                      <div class="field p-fluid mb-4 col-12 md:col-6">
                        <p-floatLabel>
                          <input id="email" type="email" pInputText class="p-inputtext w-full"
                                 [ngClass]="{'ng-invalid': true, 'ng-dirty': true }">
                          <label for="email"> E-mail <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                        </p-floatLabel>
                        <small class="p-error" *ngIf="true">
                          Podaj adres e-mail.
                        </small>
                        <small class="p-error" *ngIf="true">
                          Podany adres e-mail jest niepoprawny.
                        </small>
                      </div>
                      <div class="field p-fluid mb-4 col-12 md:col-6">
                        <p-floatLabel>
                          <input id="phone" type="text" pInputText class="p-inputtext w-full"
                                 [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                          >
                          <label for="phone"> Telefon <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                        </p-floatLabel>
                        <small class="p-error" *ngIf="true">
                          Podaj numer telefonu.
                        </small>
                        <small class="p-error" *ngIf="true">
                          Podany numer telefonu jest niepoprawny.
                        </small>
                      </div>
                      <p-divider class="col-12 field" align="center">
                        <p class="block font-medium text-900 text-xl mx-3">Notatki</p>
                      </p-divider>
                      <div class="field mb-4 col-12">
                        <p-editor id="notes" [style]="{ 'min-height': '160px' }" ></p-editor>
                      </div>
                      <p-divider class="col-12 field" align="center">
                        <p class="block font-medium text-900 text-xl mx-3">Dane szczegółowe</p>
                      </p-divider>
                      @if (client.subject != '') {
                        @if (client.subject == 'person') {
                          <div class="col-12 field p-fluid mb-4">
                            <p-floatLabel>
                              <input id="surnames" formControlName="surNames" type="text" pInputText class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                              >
                              <label for="surnames"> Imię / Imiona <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="true">Podaj imię
                              / imiona
                              .</small>
                          </div>
                          <div class="col-12 field p-fluid mb-4">
                            <p-floatLabel>
                              <input id="lastname" formControlName="lastName" type="text" pInputText class="p-inputtext w-full"
                                     aria-describedby="lastName-message"
                                     [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                              >
                              <label for="lastName"> Nazwisko <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small id="lastName-message" class="p-error"
                                   *ngIf="true">Podaj nazwisko.</small>
                          </div>
                          <div class="col-12 field p-fluid mb-4">
                            <div class="flex justify-content-center flex-wrap gap-2 sm:gap-5">
                              <div class="flex align-items-center">
                                <span>Klient posiada PESEL: </span>
                              </div>
                              <div class="flex align-items-center">
                                <p-radioButton name="hasPESEL" formControlName="hasPESEL" value="1" inputId="plCitizen"
                                               [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                                ></p-radioButton>
                                <label for="plCitizen" class="ml-2">tak</label>
                              </div>
                              <div class="flex align-items-center">
                                <p-radioButton name="hasPESEL" formControlName="hasPESEL" value="0" inputId="nonplCitizen"
                                               [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                                ></p-radioButton>
                                <label for="nonplCitizen" class="ml-2">nie</label>
                              </div>
                            </div>
                            <small class="p-error justify-content-center" *ngIf="true">
                              Czy klient posiada PESEL?
                            </small>
                          </div>
                          @if (true) {
                            @if (true) {
                              <div class="col-12 field p-fluid mb-4">
                                <p-floatLabel>
                                  <input id="pesel" formControlName="PESEL" pInputText type="text" class="p-inputtext w-full"
                                         [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                                  >
                                  <label for="pesel"> PESEL <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                                </p-floatLabel>
                                <small class="p-error" *ngIf="true">Podaj
                                  numer
                                  PESEL.</small>
                                <small class="p-error"
                                       *ngIf="true">Numer
                                  PESEL jest nieprawidłowy.</small>
                              </div>
                            } @else {
                              <div class="col-12 field p-fluid mb-4">
                                <p-floatLabel>
                                  <input id="passportNumber" formControlName="passportNumber" pInputText type="text" class="p-inputtext w-full"
                                         [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                                  >
                                  <label for="passportNumber"> Numer paszportu <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                                </p-floatLabel>
                                <small class="p-error" *ngIf="true">Podaj
                                  numer paszportu.</small>
                              </div>
                            }
                          } @else {
                            <!--<div class="col-12 field mb-4">
                              <div class="border-2 border-dashed surface-border flex-auto overflow-y-auto p-3 mx-2 my-3">
                                <p>
                                  <i class="fa-solid fa-arrow-up"></i> Czy posiadasz numer PESEL?
                                </p>
                              </div>
                            </div>-->
                          }
                        } @else if (client.subject == 'company') {
                          <!--<div class="col-12 field p-fluid mb-4">
                            <span class="p-float-label">
                              <p-dropdown [options]="companyTypes" formControlName="companyType" optionLabel="name" [showClear]="true" inputId="companyType"
                                          styleClass="w-full text-center" [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.get('companyType')?.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.get('companyType')?.errors }">
                              </p-dropdown>
                              <label for="companyType">Rodzaj przedsiębiorstwa <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </span>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.get('companyType')?.errors?.required" >Wybierz rodzaj przedsiębiorstwa.</small>
                          </div>-->
                          <div class="col-12 field p-fluid mb-4">
                            <p-floatLabel>
                              <p-inputMask id="nip" type="text" formControlName="nipNumber" styleClass="w-full" characterPattern="[A-Z]"
                                           slotChar="XX ### ### ## ##" mask="aa 999 999 99 99" [autoClear]="true"
                                           [ngClass]="{'ng-invalid': true, 'ng-dirty': true }">
                              </p-inputMask>
                              <label for="nip">NIP <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="true">
                              Podaj numer NIP z kodem kraju.
                            </small>
                          </div>
                          <!--<div class="col-12 field p-fluid mb-4">
                            <p-floatLabel>
                              <input id="regon" formControlName="regonNumber" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.get('regonNumber')?.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.get('regonNumber')?.errors }"
                              >
                              <label for="regon">REGON <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.get('regonNumber')?.errors?.required">Podaj numer REGON.</small>
                          </div>
                          @if (reservationDataForm.controls.companyType.value && this.reservationDataForm.controls.companyType.value.requireKRS) {
                            <div class="col-12 field p-fluid mb-4">
                              <p-floatLabel>
                                <input id="krs" formControlName="krsNumber" pInputText type="text" class="p-inputtext w-full"
                                       [ngClass]="{'ng-invalid': this.formSentinel.submitted && this.reservationDataForm.get('krsNumber')?.errors, 'ng-dirty': this.formSentinel.submitted && this.reservationDataForm.get('krsNumber')?.errors }"
                                >
                                <label for="krs">KRS <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                              </p-floatLabel>
                              <small class="p-error" *ngIf="this.formSentinel.submitted && this.reservationDataForm.get('krsNumber')?.errors?.required">Podaj numer KRS.</small>
                            </div>
                          }-->
                          <div class="col-12 field mb-4">
                            <p-floatLabel>
                              <input id="cn" formControlName="companyName" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': true, 'ng-dirty': true }">
                              <label for="cn">Nazwa firmy <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="true">Podaj
                              nazwę
                              firmy.</small>
                          </div>
                        }
                        @if (
                            !["", null].includes(client.subject)
                          ) {
                          <p-divider class="col-12 field" align="center">
                            <p class="block font-medium text-900 text-xl mx-3">Adres klienta</p>
                          </p-divider>
                          <div class="col-12 field mb-4">
                            <p-floatLabel>
                              <input id="address" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': true, 'ng-dirty': true }">
                              <label for="address">Adres (ulica / aleja) <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="true">Podaj
                              adres.</small>
                          </div>
                          <div class="col-12 lg:col-6 field mb-4">
                            <p-floatLabel>
                              <input id="addressNumber" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                              >
                              <label for="addressNumber">Numer ulicy <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="true">Podaj
                              numer
                              ulicy.</small>
                          </div>
                          <div class="col-12 lg:col-6 field mb-4">
                            <p-floatLabel>
                              <input id="address-apartment" pInputText type="text" class="p-inputtext w-full">
                              <label for="address-apartment">Numer lokalu</label>
                            </p-floatLabel>
                          </div>
                          <div class="col-12 field mb-4">
                            <p-floatLabel>
                              <input id="pc" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                              >
                              <label for="pc">Kod pocztowy <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span> </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="true">Podaj
                              kod
                              pocztowy.</small>
                          </div>
                          <div class="col-12 field mb-4">
                            <p-floatLabel>
                              <input id="town" pInputText type="text" class="p-inputtext w-full"
                                     [ngClass]="{'ng-invalid': true, 'ng-dirty': true }"
                              >
                              <label for="town">
                                Miejscowość <span class="fa-solid fa-asterisk vertical-align-super text-2xs"></span>
                              </label>
                            </p-floatLabel>
                            <small class="p-error" *ngIf="true">
                              Podaj miejscowość.
                            </small>
                          </div>
                        }
                      }
                      <p-divider class="w-full"></p-divider>
                      <div class="col-12">
                        <button pButton pRipple icon="fa-solid fa-floppy-disk" iconPos="left" label="Zapisz zmiany" class="w-auto mt-3"></button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-receipt mr-2 text-xl"></i>
            <span>Rezerwacje</span>
          </ng-template>
          <div class="grid">
            <div class="col-12">
              <div class="px-1 py-3 md:px-2 lg:px-4">
                <div class="surface-card p-4 shadow-2 border-round">
                  <p>Lista rezerwacji klienta.</p>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-clock-rotate-left mr-2 text-xl"></i>
            <span>Historia</span>
          </ng-template>
          <div class="card">
            <p-timeline [value]="events" align="alternate" styleClass="customized-timeline">
              <ng-template pTemplate="marker" let-event>
                <span class="shadow-2 flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1"
                      [style.backgroundColor]="event.color">
                  <i [ngClass]="event.icon"></i>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <p-card [header]="event.status" [subheader]="event.date">
                  <img *ngIf="event.image" [src]="'https://primefaces.org/cdn/primeng/images/demo/product/' + event.image" [alt]="event.name" width="200"
                       class="shadow-2" />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat
                    libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate
                    neque quas!
                  </p>
                  <button pButton label="Read more" class="p-button-text"></button>
                </p-card>
              </ng-template>
            </p-timeline>
          </div>
        </p-tabPanel>
        <p-tabPanel>
          <ng-template pTemplate="header">
            <i class="fa-solid fa-paperclip mr-2 text-xl"></i>
            <span>Zasoby</span>
          </ng-template>
          <div class="grid">
            <div class="col-12">
              <div class="px-1 py-3 md:px-2 lg:px-4">
                <div class="surface-card p-4 shadow-2 border-round">
                  <div class="grid formgrid p-fluid">
                    <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <!--<div class="field mb-4 col-12">
                      <label for="files" class="font-medium text-900">Pliki:</label>
                      <div class="card flex justify-content-center z-4">
                        <p-fileUpload id="files" class="w-full" name="demo[]" url="https://www.primefaces.org/cdn/api/upload.php" (onUpload)="onUpload($event)"
                                      [multiple]="true" accept="image/*,application/pdf" [maxFileSize]="1000000" [mode]="'advanced'" uploadLabel="Wgraj"
                                      chooseLabel="Dodaj">
                          <ng-template pTemplate="content">
                            <ul *ngIf="uploadedFiles.length" class="list-none p-0 m-0">
                              <li *ngFor="let file of uploadedFiles" class="pb-2">
                                <div class="px-4 py-1">
                                  <p-image *ngIf="file.mimeType.includes('image')" [src]="file.objectURL.changingThisBreaksApplicationSecurity"
                                           [preview]="true" [previewImageSrc]="file.objectURL.changingThisBreaksApplicationSecurity" height="25rem">
                                  </p-image>
                                  {{ file.name }} - {{ file.size }} bytes
                                </div>
                              </li>
                            </ul>
                          </ng-template>
                        </p-fileUpload>
                      </div>
                    </div>-->
                    <div class="surface-border border-top-1 opacity-50 mb-3 col-12"></div>
                    <div class="col-12">
                      <button pButton pRipple label="Save Changes" class="w-auto mt-3"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
