import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';

export interface Breadcrumb {
  label: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$: Observable<Breadcrumb[]> = this.breadcrumbsSubject.asObservable();

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs = this.createBreadcrumbs(root);
        this.breadcrumbsSubject.next(breadcrumbs);
      });
  }

  private createBreadcrumbs(route: ActivatedRouteSnapshot, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    const children: ActivatedRouteSnapshot[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.url.map(segment => segment.path).join('/');
      const routeParams: any = child.params;

      if (routeURL) {
        url += `/${routeURL}`;
      }

      if (child.data['breadcrumb']) {
        let label = child.data['breadcrumb'];

        // Jeśli etykieta jest 'null', pomijamy tę trasę
        if (label === null) {
          label = '';
        }

        if (!label) {
          label = '';
        }

        // Jeśli chcesz dynamicznie zastępować parametry w etykiecie
        Object.keys(routeParams).forEach(key => {
          label = label.replace(`:${key}`, routeParams[key]);
        });

        const breadcrumb: Breadcrumb = {
          label: label,
          url: url
        };
        breadcrumbs.push(breadcrumb);
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
