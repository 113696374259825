import {Component, HostListener, OnInit} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import 'dayjs/locale/pl';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'Przechowalnia24 - Panel rezerwacji';
  isPanel = false;

  constructor(private config: PrimeNGConfig) {
    dayjs.extend(advancedFormat);
    dayjs.locale('pl');
  }

  ngOnInit(): void {
    this.config.setTranslation({
      accept: "Akceptuj",
      addRule: "Dodaj regułę",
      apply: "Zastosuj",
      before: "Przed",
      cancel: "Anuluj",
      choose: "Wybierz",
      clear: "Wyczyść",
      contains: "Zawiera",
      dayNamesMin: ["Nd", "Po", "Wt", "Śr", "Czw", "Pi", "So"],
      dateAfter: "Data po",
      dateBefore: "Data przed",
      dateFormat: "dd.mm.yy",
      dateIs: "Data jest",
      dateIsNot: "Data nie jest",
      endsWith: "Kończy się na",
      equals: "Równa się",
      firstDayOfWeek: 1,
      gt: "Większe niż",
      gte: "Większe lub równe",
      lt: "Mniejsze niż",
      lte: "Mniejsze lub równe",
      monthNames: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
      monthNamesShort: ["Sty", "Lut", "Mar", "Kwi", "Maj", "Cze", "Lip", "Sie", "Wrz", "Paź", "Lis", "Gru"],
      matchAll: "Dopasuj wszystkie",
      matchAny: "Dopasuj którekolwiek",
      notContains: "Nie zawiera",
      notEquals: "Nie równa się",
      reject: "Odrzuć",
      removeRule: "Usuń regułę",
      startsWith: "Zaczyna się od",
      weekHeader: "Tydz"
    });
  }

  @HostListener("window:onbeforeunload",["$event"])
  closeApp(): void {
    // sessionStorage.clear();
  }

}
