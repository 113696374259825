import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatorHomeComponent } from './creator/creator-home/creator-home.component';
import { PanelHomeComponent } from './panel/panel-home/panel-home.component';
import { BookingComponent } from './creator/booking/booking.component';
import { OrderFormComponent } from './creator/order-form/order-form.component';
import { OrderSummaryComponent } from './creator/order-summary/order-summary.component';
import { CreatorLayoutComponent } from "./creator/creator-layout/creator-layout.component";
import { PanelLayoutComponent } from "./panel/panel-layout/panel-layout.component";
import { ReservationsComponent } from "./panel/reservations/reservations.component";
import { ReservationDetailsComponent } from "./panel/reservations/reservation-details/reservation-details.component";
import { LocationsComponent } from "./panel/locations/locations.component";
import { LocationDetailsComponent } from "./panel/locations/location-details/location-details.component";
import { StoragesComponent } from "./panel/storages/storages.component";
import { StorageDetailsComponent } from "./panel/storages/storage-details/storage-details.component";
import { StorageContainersComponent } from "./panel/storageContainers/storage-containers.component";
import { StorageContainerDetailsComponent } from "./panel/storageContainers/storage-container-details/storage-container-details.component";
import { LoginComponent } from "./shared/login/login.component";
import { authGuard } from "./auth.guard";
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { OrderPaymentComponent } from "./creator/order-payment/order-payment.component";
import { ClientsComponent } from "./panel/clients/clients.component";
import { ClientDetailsComponent } from "./panel/clients/client-details/client-details.component";

const routes: Routes = [
  {
    path: "creator",
    component: CreatorLayoutComponent ,
    children: [
      {
        path: "",
        component: CreatorHomeComponent,
        title: "Wybór lokalizacji"
      },
      {
        path: "booking",
        component: BookingComponent,
        title: "Rezerwacja kontenerów"
      },
      {
        path: "orderForm",
        component: OrderFormComponent,
        title: "Formularz rezerwacji"
      },
      {
        path: "payment",
        component: OrderPaymentComponent,
      },
      {
        path: "orderSummary/:id",
        pathMatch: "prefix",
        component: OrderSummaryComponent,
        title: "Podsumowanie rezerwacji"
      },
    ]
  },
  {
    path: "panel",
    component: PanelLayoutComponent,
    data: { },
    canActivate: [authGuard],
    children: [
      {
        path: "",
        component: PanelHomeComponent,
        data: { breadcrumb: null },
      },
      {
        path: "reservations",
        data: { breadcrumb: "Rezerwacje" },
        title: "Panel - Rezerwacje",
        children: [
          {
            path: "",
            component: ReservationsComponent,
            data: { breadcrumb: null },
          },
          {
            path: "details/:id",
            component: ReservationDetailsComponent,
            data: { breadcrumb: "Szczegóły rezerwacji (ID: :id)" },
            title: "Panel - Szczegóły rezerwacji"
          },
        ]
      },
      {
        path: "locations",
        data: { breadcrumb: "Lokalizacje" },
        title: "Panel - Lokalizacje",
        children: [
          {
            path: "",
            component: LocationsComponent,
            data: { breadcrumb: null },
          },
          {
            path: "details/:id",
            component: LocationDetailsComponent,
            data: { breadcrumb: "Szczegóły lokalizacji (ID: :id)" },
          },
        ]
      },
      {
        path: "storageContainers",
        data: { breadcrumb: "Magazyny" },
        title: "Panel - Magazyny",
        children: [
          {
            path: "",
            component: StorageContainersComponent,
            data: { breadcrumb: null },
          },
          {
            path: "details/:id",
            component: StorageContainerDetailsComponent,
            data: { breadcrumb: "Szczegóły magazynu (ID: :id)" }
          },
        ]
      },
      {
        path: "storages",
        data: { breadcrumb: "Rodzaje magazynów" },
        title: "Panel - Rodzaje magazynów",
        children: [
          {
            path: "",
            component: StoragesComponent,
            data: { breadcrumb: null },
          },
          {
            path: "details/:id",
            component: StorageDetailsComponent,
            data: { breadcrumb: "Szczegóły rodzaju magazynu (ID: :id)" }
          },
        ]
      },
      {
        path: "clients",
        data: { breadcrumb: "Klienci" },
        title: "Panel - Klienci",
        children: [
          {
            path: "",
            component: ClientsComponent,
            data: { breadcrumb: null },
          },
          {
            path: "details/:id",
            component: ClientDetailsComponent,
            data: { breadcrumb: "Szczegóły klienta (ID: :id)" },
            title: "Panel - Szczegóły klienta",
          },
        ]
      }
    ]
  },
  { path: "login", component: LoginComponent, title: "Logowanie" },
  { path: "reset-password", component: ResetPasswordComponent, title: "Resetowanie hasła" },
  { path: "", redirectTo: '/creator', pathMatch: "prefix" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
