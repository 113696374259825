<div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12">
      <p-table #dt1 [value]="groups" [scrollable]="true" scrollHeight="64vh" [tableStyle]="{'min-width': '50rem'}"
               dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5, 10, 15, 25, 50, 100]" [loading]="loading"
               [paginator]="true" [showJumpToPageInput]="true" currentPageReportTemplate="Wiersze: {first} - {last} / {totalRecords}, Strona: {currentPage} / {totalPages}"
               [globalFilterFields]="['name', 'country.name', 'representative.name', 'date', 'balance', 'status']" sortMode="multiple"
               [(selection)]="selectedCustomers" styleClass="p-datatable-striped"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-row justify-content-between">
            <div class="flex align-items-center justify-content-center">
              <!-- <div *ngIf="selectedCustomers !== undefined" class="grid align-items-center justify-content-center">
                <div class="col-8">
                  <button pButton label="Zmień statusy" class="p-button-outlined p-button-info" icon="fa-solid fa-pen-to-square"></button>
                </div>
                <div class="col-4">
                  <button pButton label="Usuń" class="p-button-outlined p-button-danger" icon="fa-regular fa-trash-can"></button>
                </div>
              </div> -->
            </div>
            <div class="flex align-items-center justify-content-between">
              <span class="p-input-icon-left mr-1">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="searchInput(dt1, $event);" placeholder="Search keyword" />
              </span>
              <button pButton label="Wyczyść filtry" class="p-button-outlined ml-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <!--<th style="width: 5rem;">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>-->
            <th style="min-width:15rem" pSortableColumn="name">
              <div class="flex align-items-center justify-content-around">
                Name
                <p-sortIcon field="name"></p-sortIcon>
                <p-columnFilter class="ml-1" type="text" field="name" display="toolbox"></p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem" pSortableColumn="thumbnail">
              <div class="flex align-items-center justify-content-around">
                Miniatury
                <!--<p-sortIcon field="representative.name"></p-sortIcon>
                <p-columnFilter field="representative" matchMode="in" display="toolbox" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">Agent Picker</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-multiSelect [ngModel]="value" [options]="representatives" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <img [alt]="option.label" src="https://primefaces.org/cdn/primeng/images/demo/avatar/{{ option.image }}" width="24" class="vertical-align-middle" />
                          <span class="ml-1 mt-1">{{ option.name }}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>-->
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-group let-rowIndex="rowIndex">
          <tr [pSelectableRow]="group" [pSelectableRowIndex]="rowIndex" (click)="openRow($event, group.id);">
            <!--<td>
              <p-tableCheckbox [value]="customer"></p-tableCheckbox>
            </td>-->
            <td>
              {{ group.name }}
            </td>
            <td class="flex justify-content-center">
              <img [alt]="group.thumbnail" src="{{ group.thumbnail }}" width="128" style="vertical-align: middle;" />
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No customers found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
