<div class="grid mb-5">
  <div class="col-12 flex flex-column">
    <ul class="list-none p-0 m-0 flex flex-column md:flex-row">
      <li class="relative mr-0 md:mr-8 flex-auto">
        <div
          class="border-2 border-green-400 hover:border-blue-700 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1">
          <i class="fa-solid fa-location-dot text-green-500 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
          <div>
            <div class="text-900 font-medium mb-1">
              Lokalizacja
            </div>
            <span class="text-600 text-sm hidden md:block">Miejsce Twojego kontenera.</span>
          </div>
        </div>
        <div class="w-full absolute top-50 left-100 surface-300 hidden md:block"
          style="transform: translateY(-50%); height: 2px">
        </div>
      </li>
      <li class="relative mr-0 md:mr-8 flex-auto">
        <div
          class="border-3 border-blue-500 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1">
          <i class="fa-solid fa-calendar-check text-blue-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
          <div>
            <div class="text-blue-600 font-medium mb-1">Rezerwacja</div>
            <span class="text-600 text-sm hidden md:block">Zarezerwuj swój magazyn.</span>
          </div>
        </div>
        <div class="w-full absolute top-50 left-100 surface-300 hidden md:block"
          style="transform: translateY(-50%); height: 2px"></div>
      </li>
      <!-- <li class="relative mr-0 md:mr-8 flex-auto">
        <div
          class="border-1 border-300 border-round p-3 surface-card flex flex-column md:flex-row align-items-center z-1">
          <i class="fa-solid fa-piggy-bank text-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
          <div>
            <div class="text-900 font-medium mb-1">Wyliczenie</div>
            <span class="text-600 text-sm hidden md:block">Zobacz, ile zaoszczędzisz!</span>
          </div>
        </div>
        <div class="w-full absolute top-50 left-100 surface-300 hidden md:block"
          style="transform: translateY(-50%); height: 2px"></div>
      </li> -->
      <!-- <li class="relative mr-0 md:mr-8 flex-auto">
        <div
          class="border-1 border-300 border-round p-3 surface-50 flex flex-column md:flex-row align-items-center z-1">
          <i class="fa-solid fa-address-card text-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
          <div>
            <div class="text-900 font-medium mb-1">Dane</div>
            <span class="text-600 text-sm hidden md:block">Przedstaw się</span>
          </div>
        </div>
        <div class="w-full absolute top-50 left-100 surface-300 hidden md:block"
          style="transform: translateY(-50%); height: 2px"></div>
      </li> -->
      <li class="relative mr-0 md:mr-8 flex-auto">
        <div
          class="border-1 border-300 border-round p-3 surface-50 flex flex-column md:flex-row align-items-center z-1">
          <i class="fa-regular fa-credit-card text-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
          <div>
            <div class="text-900 font-medium mb-1">Dane i Płatność</div>
            <span class="text-600 text-sm hidden md:block">Wybierz dogodną dla Ciebie płatność</span>
          </div>
        </div>
        <div class="w-full absolute top-50 left-100 surface-300 hidden md:block"
          style="transform: translateY(-50%); height: 2px"></div>
      </li>
      <li class="relative flex-auto">
        <div
          class="border-1 border-300 border-round p-3 surface-50 flex flex-column md:flex-row align-items-center z-1">
          <i class="pi pi-check-circle text-600 text-2xl md:text-4xl mb-2 md:mb-0 mr-0 md:mr-3"></i>
          <div>
            <div class="text-900 font-medium mb-1">Potwierdzenie</div>
            <span class="text-600 text-sm hidden md:block">Witamy nowego klienta.</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
