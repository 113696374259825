import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "../services/auth/auth.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  validToken: boolean = false;
  validReset: boolean = false;

  constructor(
    private fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) {
    this.resetPasswordForm = this.fb.group({
      passwordNew: ["", [Validators.required, Validators.minLength(3)]],
      passwordRepeat: ["", [Validators.required, Validators.minLength(3)]],
    });
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams.token) {
      this.authService.checkResetToken(this.activatedRoute.snapshot.queryParams.token).subscribe({
        next: (response: any) => {
          if (response.status === 200) {
            this.validToken = true;
          } else if (response.status === 404) {
            this.validToken = false;
          }
        },
        error: (error: any) => {
          console.error("Error: ", error);
          this.validToken = false;
        }
      });
    }
  }
  resetPassword() {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    this.authService.resetPassword(
      this.activatedRoute.snapshot.queryParams.token,
      this.resetPasswordForm.controls.passwordNew.value
    ).subscribe({
      next: (response: any) => {
        if (response.status === 200) {
          this.validReset = true;
          setTimeout(() => {
            this.validReset = false;
            this.router.navigate(['/login']);
          }, 8000);
        }
      },
      error: (error: any) => {
        console.error("Error: ", error);
      }
    });
  }
}
