<div class="flex flex-column flex-auto w-full h-full sm:w-screen sm:h-screen">
  <div style="background: url('assets/images/blocks/signin/signin-2.jpg') no-repeat; background-size: cover"
       class="px-3 py-8 sm:px-8 w-full h-full justify-content-center align-content-center">
    <div class="flex flex-wrap mx-1 sm:mx-3 md:mx-5 lg:mx-7 xl:mx-8">
      <div class="w-full md:w-6 xl:w-5 p-4 lg:p-7" style="background-color: rgba(255, 255, 255, .7)">
        <img src="assets/images/blocks/logos/bastion.svg" alt="Image" height="50" class="mb-4">
        <div class="text-xl text-900 font-500 mb-3">Witamy w Przechowalnia24.pl</div>
        <p class="text-600 line-height-3 mt-0 mb-6">Świat Twoich schowków.</p>
        <div class="text-xl text-900 font-500 mb-3">Nowości w aplikacji</div>
        <ul class="list-none overflow-x-hidden overflow-y-auto p-0 m-0 max-h-18rem">
          <li class="flex align-items-start mb-4">
            <div>
              <span class="flex align-items-center justify-content-center bg-purple-400" style="width:38px;height:38px;border-radius:10px">
                  <i class="text-xl text-white pi pi-inbox"></i>
              </span>
            </div>
            <div class="ml-3">
              <span class="font-medium text-900">Unlimited Inbox</span>
              <p class="mt-2 mb-0 text-600 line-height-3">
                Tincidunt nunc pulvinar sapien et. Vitae purus faucibus ornare suspendisse sed nisi lacus sed
                viverra.
              </p>
            </div>
          </li>
          <li class="flex align-items-start mb-4">
            <div>
              <span class="flex align-items-center justify-content-center bg-purple-400" style="width:38px;height:38px;border-radius:10px">
                  <i class="text-xl text-white pi pi-shield"></i>
              </span>
            </div>
            <div class="ml-3">
              <span class="font-medium text-900">Premium Security</span>
              <p class="mt-2 mb-0 text-600 line-height-3">Scelerisque purus semper eget duis at tellus at urna. Sed risus pretium quam vulputate.</p>
            </div>
          </li>
          <li class="flex align-items-start">
            <div>
              <span class="flex align-items-center justify-content-center bg-purple-400" style="width:38px;height:38px;border-radius:10px">
                  <i class="text-xl text-white pi pi-globe"></i>
              </span>
            </div>
            <div class="ml-3">
              <span class="font-medium text-900">Cloud Backups Inbox</span>
              <p class="mt-2 mb-0 text-600 line-height-3">Egestas sed tempus urna et. Auctor elit sed vulputate mi sit amet mauris commodo.</p>
            </div>
          </li>
          <li class="flex align-items-start">
            <div>
              <span class="flex align-items-center justify-content-center bg-purple-400" style="width:38px;height:38px;border-radius:10px">
                  <i class="text-xl text-white pi pi-globe"></i>
              </span>
            </div>
            <div class="ml-3">
              <span class="font-medium text-900">Cloud Backups Inbox</span>
              <p class="mt-2 mb-0 text-600 line-height-3">Egestas sed tempus urna et. Auctor elit sed vulputate mi sit amet mauris commodo.</p>
            </div>
          </li>
          <li class="flex align-items-start">
            <div>
              <span class="flex align-items-center justify-content-center bg-purple-400" style="width:38px;height:38px;border-radius:10px">
                  <i class="text-xl text-white pi pi-globe"></i>
              </span>
            </div>
            <div class="ml-3">
              <span class="font-medium text-900">Cloud Backups Inbox</span>
              <p class="mt-2 mb-0 text-600 line-height-3">Egestas sed tempus urna et. Auctor elit sed vulputate mi sit amet mauris commodo.</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="w-full md:w-6 xl:w-7 p-4 lg:p-7 surface-card">
        <div class="text-900 text-2xl font-medium mb-6">Logowanie</div>
        <form [formGroup]="localForm" (ngSubmit)="localLogin();">
          <div class="field mb-4">
            <p-floatLabel>
              <input id="login" type="text" placeholder="Podaj login" formControlName="login" pInputText class="w-full">
              <label for="login" class="block text-900">Login</label>
            </p-floatLabel>
            <small class="inline-block w-full p-error text-center" *ngIf="this.localFormSentinel.submitted && this.localForm.controls.login.errors">
              Podaj login.
            </small>
          </div>
          <div class="mb-4">
            <p-floatLabel>
              <p-password placeholder="Podaj hasło" formControlName="password" inputId="password" class="inline-block w-full" inputStyleClass="w-full"
                          toggleMask="true" feedback="false" styleClass="w-full">
              </p-password>
              <label for="password" class="block text-900">Hasło</label>
            </p-floatLabel>
            <small class="inline-block w-full p-error text-center" *ngIf="this.localFormSentinel.submitted && this.localForm.controls.password.errors">
              Podaj hasło.
            </small>
          </div>
          <div class="grid">
            <div class="col-4">
              <div class="flex align-items-center">
                <!--<p-checkbox id="rememberme3" [binary]="true" styleClass="mr-2"></p-checkbox>
                <label for="rememberme3">Zapamiętaj mnie</label>-->
              </div>
            </div>
            <div class="col-4">
              <small class="inline-block w-full p-error text-center" *ngIf="this.localFormSentinel.submitted && this.localForm.errors">
                Błędne dane logowania.
              </small>
            </div>
            <div class="col-4 text-right">
              <a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"></a>
              <p-button styleClass="font-medium text-blue-500" tabindex="1" label="Resetuj hasło" [link]="true"
                        (click)="showForgotPassword = true;" />
            </div>
          </div>
          <button pButton pRipple label="Zaloguj" type="submit" icon="pi pi-user" class="w-full"></button>
        </form>

        <p-divider align="center" styleClass="my-6">
          <span class="text-600 font-normal text-sm">LUB</span>
        </p-divider>

        <button pButton pRipple label="Zaloguj z Google" icon="pi pi-google" class="w-full p-button-outlined p-button-danger"></button>

        <div class="flex flex-column mt-6 text-center text-600 text-lg">
          <span>Nie masz jeszcze konta?</span>
          <p-button styleClass="font-medium text-blue-500" tabindex="2" icon="pi pi-user-plus" iconPos="left" label="Załóż je" [link]="true"
                    (click)="register();" />
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [(visible)]="showForgotPassword" appendTo="body" [modal]="true" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '52vw'}"
          header="Edit Payment Method" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <div class="flex flex-column gap-2">
      <h1 class="m-0 text-900 font-semibold text-xl line-height-3">Zresetuj hasło</h1>
      <span class="text-600 text-base">
        Podaj adres e-mail połączony z Twoim kontem.<br />
        Na powiązany adres zostanie wysłany link do zresetowania hasła.
      </span>
    </div>
  </ng-template>
  <form class="flex flex-column gap-3 mt-3"
        [formGroup]="resetPasswordForm"
        (ngSubmit)="resetPassword();"
        #resetForm="ngForm"
  >
    <div>
      <p-floatLabel>
        <input type="text" formControlName="emailReset" class="w-full" id="emailReset" pInputText />
        <label for="emailReset" class="block mb-1 text-color text-base">
          <i class="fa-regular fa-envelope mr-1"></i> Adres e-mail
        </label>
      </p-floatLabel>
    </div>
  </form>
  <ng-template pTemplate="footer">
    <div class="flex gap-3 justify-content-center border-top-1 surface-border pt-5">
      <button pButton pRipple label="Anuluj" (click)="showForgotPassword = false;" class="p-button-text"></button>
      <button pButton pRipple label="Resetuj" [loading]="loadingResetPassword" (click)="loadingResetPassword = true; this.resetForm.ngSubmit.emit(undefined);"
              class="p-button-rounded"></button>
    </div>
  </ng-template>
</p-dialog>
