import { Component, OnDestroy, OnInit } from "@angular/core";
import { CreatorService } from "../../shared/data/services/creator.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'order-summary',
  templateUrl: './order-summary.component.html',
  styleUrl: './order-summary.component.scss'
})
export class OrderSummaryComponent implements OnInit, OnDestroy {

  reservation: any;
  reservationStatuses: {[key: string]: boolean } = {
    open: false,
    submitted: false,
    payment_processing: false,
    awaiting_payment: false,
    paid: false,
    confirmed: false,
    pending: false,
    ready: false,
    completed: false,
    cancelled: false,
    rejected: false
  };

  constructor(private readonly router: ActivatedRoute, private readonly creatorService: CreatorService) {
  }

  ngOnInit() {
    console.log("OrderSummaryComponent");
    this.creatorService
      .getReservation(this.router.snapshot.params.id)
      .subscribe((result: any) => {
        console.log(result);
        this.reservation = result.body;
        for (const key of Object.keys(this.reservationStatuses)) {
          this.reservationStatuses[key] = true;
          if (this.reservation.reservationStatus.replace(" ", "_") === key) {
            break;
          }
        }

      });
  }

  ngOnDestroy() {
  }
}
