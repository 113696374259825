import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Table } from "primeng/table";
import { CreatorService } from "../../shared/data/services/creator.service";
import { FetchDataService } from "../../shared/data/services/fetch-data.service";
import { ConfirmationService, MessageService } from "primeng/api";
import { AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { forkJoin } from "rxjs";

@Component({
  selector: 'storage-containers',
  templateUrl: './storage-containers.component.html',
  styleUrl: './storage-containers.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class StorageContainersComponent implements OnInit {
  storageContainers: any[] = [];
  locationList!: any[];
  filteredLocations: any[] = [];

  selectedStorageContainers!: any[];

  loading: boolean = true;
  storageStatuses!: any[];
  selectedStatus: any;
  filteredStatuses: any[] = [];
  storageGroups!: any[];
  showStorageContainersDialog: boolean = false;
  storageContainerAddForm: FormGroup;
  protected readonly console = console;

  constructor(
    private router: Router,
    private readonly creatorService: CreatorService,
    private readonly fetchDataService: FetchDataService,
    private readonly messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: FormBuilder
  ) {

    this.storageContainerAddForm = this.fb.group({
      storageNumber: [{ value: '', disabled: false }, [Validators.required]],
      status: [{ value: '', disabled: false }, [Validators.required]],
      notes: [{ value: '', disabled: true }, []],
      group: [{ value: '', disabled: false }, [Validators.required]],
      location: [{ value: '', disabled: false }, [Validators.required]],
    });
  }

  ngOnInit() {
    this.loading = true;
    forkJoin([
      this.fetchDataService.getStorageStatuses(),
      this.fetchDataService.getStorageGroups(),
      this.fetchDataService.getStorageContainers(),
      this.fetchDataService.getLocations(),
    ]).subscribe({
      next: ([statuses, groups, containers, locations]) => {
        this.storageStatuses = statuses;
        this.storageGroups = groups;
        this.storageContainers = containers.body as any[];
        this.locationList = locations.body as any[];
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching data:', error);
        this.loading = false;
      },
    });
    this.storageContainerAddForm.controls.status.valueChanges.subscribe((value) => {
      if (value === 'serviced') {
        this.storageContainerAddForm.controls.notes.setValidators([Validators.required]);
        this.storageContainerAddForm.controls.notes.enable();
      } else {
        this.storageContainerAddForm.controls.notes.setValidators([]);
        this.storageContainerAddForm.controls.notes.disable();
      }
    });
  }

  refreshData() {
    this.loading = true;
    this.fetchDataService.getStorageContainers().subscribe({
      next: (response: any) => {
        this.storageContainers = response.body as any[];
        this.loading = false;
      },
      error: (error) => {
        console.error('Error fetching data:', error);
        this.loading = false;
      },
      complete: () => {
        this.clearFormControls();
      },
    });
  }

  clearFormControls() {
    this.storageContainerAddForm.reset();
    this.storageContainerAddForm.setErrors(null);
    this.storageContainerAddForm.markAsUntouched();
    for (const control in this.storageContainerAddForm.controls) {
      this.storageContainerAddForm.controls[control].markAsUntouched();
      this.storageContainerAddForm.controls[control].setErrors(null);
    }
    this.storageContainerAddForm.markAsPristine();
  }

  clear(table: Table) {
    table.clear();
  }

  searchInput(dt: Table, event: any) {
    // console.log(event);
    dt.filterGlobal(event.target.value, 'contains');
  }

  getSeverity(status: any) {
    switch (status) {
      case 'available':
        return 'success';
      case 'reserved':
        return 'warning';
      case 'booked':
        return 'info';
      case 'rented':
        return 'danger';
      case 'serviced':
        return 'secondary';
      case 'disabled':
        return 'contrast';
      default:
        return undefined;
    }
  }

  getValueStatus(value: string) {
    const obj = this.storageStatuses.find((status) => status.value === value);
    return obj ? obj.name : '';
  }

  filterStatuses(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.storageStatuses as any[]).length; i++) {
      let storageStatus = (this.storageStatuses as any[])[i];
      if (storageStatus.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(storageStatus);
      }
    }

    this.filteredStatuses = filtered;
  }

  filterLocations(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < this.locationList.length; i++) {
      let location = this.locationList[i];
      if (location.name.toLocaleLowerCase().includes(query.toLocaleLowerCase())) {
        filtered.push(location);
      }
    }

    this.filteredLocations = filtered;
  }

  openRow(event: any, orderId: string) {
    // console.log("Open row");
    // console.log(event);
    if (
      typeof event.target.className == 'string' &&
      !event.target.className.includes('p-checkbox-box') &&
      !event.target.classList.contains("disableSelect")
    ) {
      // console.log(orderId);
      this.router.navigate(
        ['/panel/storageContainers/details', orderId]
      );
    }
  }

  hideDialog() {
    this.showStorageContainersDialog = false;
    this.clearFormControls();
  }

  onSubmitDialog() {
    console.log("storageContainerAddForm");
    console.log(this.storageContainerAddForm);
    if (this.storageContainerAddForm.valid) {
      this.creatorService.createStorageContainer(this.storageContainerAddForm.value)
        .subscribe({
          next: (response: any) => {
            this.showStorageContainersDialog = false;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Storage container created' });
          },
          error: (error) => {
            console.error('Error creating storage container:', error);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.message, life: 8000 });
          },
          complete: this.refreshData.bind(this),
        });
    }
  }

  confirmDialog(event: Event) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `
        Czy chcesz usunąć wybrane kontenery?<br/>
        <ul>
          ${this.selectedStorageContainers.map((item) => `<li>${item.storageNumber}</li>`).join(' ')}
        </ul>
      `,
      header: 'Usuwanie',
      acceptIcon: "fa-regular fa-trash-can mr-2",
      acceptButtonStyleClass: "p-button-success",
      rejectIcon: "fa-solid fa-circle-xmark mr-2",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.selectedStorageContainers.forEach((item) => {
          this.creatorService.deleteStorageContainer(item.id).subscribe({
            next: (response: any) => {
              this.messageService.add({ severity: 'success', summary: 'Usunięto', detail: `Prawidłowo usunięto kontener ${item.storageNumber}.`, life: 4000 });
            },
            error: (error) => {
              this.messageService.add({ severity: 'error', summary: 'Błąd', detail: `Wystąpił błąd podczas usuwania kontenera ${item.storageNumber}.`, life: 4000 });
            },
            complete: () => {
              this.refreshData();
              this.selectedStorageContainers = this.selectedStorageContainers.filter((storageContainer) => storageContainer.id !== item.id);
            }
          });
        });

      },
      reject: () => {
        this.messageService.add({ severity: 'info', summary: 'Anulowanie', detail: 'Anulowano usuwanie.', life: 5000 });
      }
    });
  }
}
