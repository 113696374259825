import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArrowNavigatorComponent } from './arrow-navigator.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  bootstrap: [ArrowNavigatorComponent]
})
export class ArrowNavigatorModule { }
