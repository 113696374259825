import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError, Observable, of, tap } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authStatus = new BehaviorSubject<boolean | null>(null);
  constructor(private http: HttpClient, private router: Router) {}

  // Przykładowa metoda do sprawdzania, czy użytkownik jest zalogowany
  checkAuth(): Observable<boolean> {
    return this.http.get('/api/auth/status', { withCredentials: true, observe: 'response' })
      .pipe(
        tap(response => {
          const body = response.body as { authenticated: boolean } | null;
          this.authStatus.next(body?.authenticated ?? false);
        }),
        map(response => {
          const body = response.body as { authenticated: boolean } | null;
          return body?.authenticated ?? false;
        }),
        catchError(() => {
          this.authStatus.next(false);
          return of(false);
        })
      );
  }

  login(login: string, password: string): Observable<Object> {
    return this.http.post('/api/auth', {login, password}, { withCredentials: true, observe: 'response' })
      .pipe(
        tap((response) => {
          if (response.status === HttpStatusCode.Ok) {
            this.authStatus.next(true);
          } else {
            this.authStatus.next(false);
          }
        }),
        map((response) => {
          return response;
        }),
        catchError((error) => {
          this.authStatus.next(false);
          return of(error);
        })
      );
  }

  // Przykładowa metoda do wylogowywania użytkownika
  logout(): Observable<Object> {
    return this.http.delete('/api/auth', { withCredentials: true, observe: 'response' }).pipe(
      tap((response) => {
        if (response.status === HttpStatusCode.Ok) {
          this.authStatus.next(true);
        } else {
          this.authStatus.next(false);
        }
      }),
    );
  }

  beginResetPassword(data: any) {
    return this.http.patch("/api/auth", data, { observe: 'response' }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    return of({ ...error });
  }

  checkResetToken(token: any) {
    return this.http.get(`/api/auth/token/${token}`, { observe: 'response' }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }


  resetPassword(token: string, password: string) {
    return this.http.patch(`/api/auth/passwordNew/${token}`, { passwordNew: password }, { observe: 'response' }).pipe(
      catchError(this.handleError)
    );
  }
}
