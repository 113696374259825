<div *ngIf="!isPanel" class="grid mx-0 px-8" style="background-color: #122135; color: #ffffff !important;">
  <div class="col-4">
    <h2>Nawigacja</h2>
    <nav>
      <ul>
        <li>Cennik</li>
        <li>Usługi dodatkowe</li>
        <li>Poszukujemy gruntów</li>
        <li>Polityka prywatności</li>
        <li>Kontakt</li>
      </ul>
    </nav>
  </div>
  <div class="col-4">
    <h2>Rzetelna firma</h2>
    <p>Od 2018 roku nasz firma Global StorageContainerEntity Poland sp. z o.o. uczestniczy w programie „Rzetelna Firma”.</p>
  </div>
  <div class="col-4">
    <h2>Dane kontaktowe</h2>
    <p>
      ul. Poznańska 57D,<br>05-850 Jawczyce<br />
      tel. <a href="tel:+48883839389">+48 883 839 389</a><br />
      <a href="mailto:biuro@przechowalnia24.pl">biuro&#64;przechowalnia24.pl</a><br />
      <a href="https://przechowalnia24.pl/" target="_blank" rel="noreferrer noopener">przechowalnia24.pl</a>
    </p>
  </div>
</div>
<div class="grid mx-0" style="background-color: #0f172a; color: #576b77 !important;">
  <div class="col-12">
    <p class="w-full text-center">
      Copyright © 2024 by Global Storage Poland sp. z o.o. | Powered by <a href="https://imerti.pl" target="_blank">Imerti</a>
    </p>
    <div class="flex align-items-center justify-content-center">
      <a class="cursor-pointer mr-5">
        <i class="pi pi-facebook"></i>
      </a>
      <a class="cursor-pointer">
        <i class="pi pi-instagram"></i>
      </a>
    </div>
    <div class="flex align-items-center justify-content-center">
      <label for="version">Wersja: </label>
      <p-button class="p-0" [ngClass]="{'disable-link': !isPanel}" id="version" label="0.0.0" [link]="true"></p-button>
    </div>
  </div>
</div>
