import { LocationsPromotions } from "./locations-promotions";
import { Address } from "./address";

// @Todo: zmienić pole `address` na `place` oraz jego typ na obiekt Place {address: string, postalCode: string/PostalCode, town:string}

export interface Yard {
  name: string;
  value: string;
}

export class Location {
  id: any;
  name: string;
  thumbnail: any;
  place: Address = new Address();
  orderVisibility: number;
  available: boolean;
  yards: Yard[] = [];
  promotions: LocationsPromotions[];
  bankAccountNumber: string = '';
  containersAvailableCount!: number;

  constructor(id: any = null, name: string = '', thumbnail: string = '', orderVisibility: number = -1, available: boolean = false) {
    this.id = id;
    this.name = name;
    this.thumbnail = thumbnail;
    this.orderVisibility = orderVisibility;
    this.available = available;
    this.promotions = [];
  }

  /*setPromotions(promotions: IPromotion[]) {
    this.promotions = promotions;
  }

  getPromotions() {
    return this.promotions;
  }

  addPromotionForLocalization(promotion: IPromotion) {
    this.promotions.push(promotion);
  }*/
}
