import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { catchError, filter, first, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from "./shared/services/auth/auth.service";

export const authGuard: CanActivateFn = (): Observable<boolean> => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.checkAuth().pipe(
    filter((isAuth): isAuth is boolean => isAuth !== null),
    first(),
    map(isAuth => {
      console.log(isAuth);
      if (isAuth) {
        return true;
      } else {
        router.navigate(['/login']);
        return false;
      }
    }),
    catchError((err) => {
      console.error('Error checking authentication status:', err);
      router.navigate(['/login']);
      return of(false);
    })
  );
};
