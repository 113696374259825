<div class="flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border" style="height:auto;">
  <ul class="list-none p-0 m-0 surface-card flex font-medium overflow-y-hidden overflow-x-auto">
    <li *ngFor="let breadcrumb of breadcrumbs; let i = index; let last = last;" class="relative p-3">
      <div *ngIf="breadcrumb.label" class="flex items-center">
        <ng-container *ngIf="i === 0">
          <a class="cursor-pointer" [routerLink]="[breadcrumb.url]">
            <i class="fa-solid fa-house text-blue-500"></i>
          </a>
        </ng-container>
        <ng-container *ngIf="i > 0 && !last">
          <div class="absolute left-0 top-0 z-1" style="border-left: 20px solid var(--surface-card); border-top: 26px solid transparent; border-bottom: 26px
          solid transparent; width: 0; height: 0"></div>
          <a [routerLink]="[breadcrumb.url]" class="cursor-pointer text-blue-500 pl-4 white-space-nowrap">
            {{ breadcrumb.label }}
          </a>
          <div class="absolute top-0" style="left: 1px; border-left: 20px solid var(--surface-border); border-top: 26px solid transparent; border-bottom: 26px
           solid transparent; width: 0; height: 0"></div>
        </ng-container>
        <ng-container *ngIf="last && i > 0">
          <div class="absolute left-0 top-0 z-1" style="border-left: 20px solid var(--surface-card); border-top: 26px solid transparent; border-bottom: 26px
          solid transparent; width: 0; height: 0"></div>
          <span class="text-blue-500 pl-4 white-space-nowrap">
            {{ breadcrumb.label }}
          </span>
          <div class="absolute top-0" style="left: 1px; border-left: 20px solid var(--surface-border); border-top: 26px solid transparent; border-bottom: 26px
           solid transparent; width: 0; height: 0"></div>
        </ng-container>
      </div>
    </li>
  </ul>
</div>
