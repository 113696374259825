<div class="text-center px-2 py-4 md:px-6 lg:px-8">
  <steps-router></steps-router>

  <div class="grid mb-4">
    <div class="flex col-12 xl:col-12 justify-content-center">
      <span class="p-buttonset">
        <button pButton label="Dodaj magazyn" icon="fa-solid fa-plus" pStyleClass="#slideProducts" enterFromClass="hidden"
                enterActiveClass="fadeinright" leaveToClass="hidden" leaveActiveClass="fadeoutright" [hideOnOutsideClick]="true"
                (click)="loadAssortment()"
        >
        </button>
        <button pButton label="Wyczyść listę" icon="fa-solid fa-trash" (click)="clearCart($event);"></button>
      </span>
    </div>
  </div>

  <section class="grid">
    @if(reservationDetails.length > 0) {
      @for (item of reservationDetails; track item.locationStoragesGroup.id; let cartIndex = $index) {
        <div class="col-12 xl:col-12">
          <article class="flex flex-column md:flex-row w-full gap-3 p-3 border-round border-1 surface-border surface-card">
            <div class="relative">
              <p-image src="{{item.locationStoragesGroup.thumbnailUrl}}" alt="Image" class="border-round inline-block md:w-20rem md:h-auto" imageClass="w-12 h-auto">
              </p-image>
              <p class="absolute px-2 py-1 border-round-lg text-sm md:text-base lg:text-lg font-normal text-white mt-0 mb-0"
                style="background-color: rgba(255, 255, 255, 0.3); backdrop-filter: blur(15px); top: 3%; left: 3%;">
                @if (item.locationStoragesGroup.nettoUnitPromoPrice != null) {
                  <span class="text-red-500 font-bold">
                    <span class="text-gray-400 font-normal text-xs promoPrice">
                      {{ item.locationStoragesGroup.nettoUnitPrice | currency:'PLN'}} netto |
                      {{ item.locationStoragesGroup.nettoUnitPrice * 1.23 | currency:'PLN'}}
                      brutto / msc
                    </span>
                    <br/>
                  </span>
                  <span class="text-gray-700 font-semibold text-base">
                    {{ item.locationStoragesGroup.nettoUnitPromoPrice | currency:'PLN' }} netto |
                    {{ item.locationStoragesGroup.nettoUnitPromoPrice * 1.23 | currency:'PLN' }}
                    brutto / msc
                  </span>
                } @else {
                  <span class="text-gray-700 font-semibold text-base">
                    {{ item.locationStoragesGroup.nettoUnitPrice | currency:'PLN' }} netto |
                    {{ item.locationStoragesGroup.nettoUnitPrice * 1.23 | currency:'PLN' }}
                    brutto / msc
                  </span>
                }
              </p>
            </div>
            <div class="flex flex-column w-full gap-3">
              <div class="flex w-full justify-content-between align-items-center flex-wrap gap-3">
                <p class="font-medium text-lg mt-0 mb-0">{{ item.locationStoragesGroup.group.name }}</p>
                <button type="button" pButton icon="pi pi-trash" class="p-button-danger p-button-text p-2 p-button-rounded"
                        (click)="removeFromCart($event, cartIndex, item);">
                </button>
              </div>
              <div class="flex flex-wrap justify-content-between xl:h-2rem mt-0">
                <div class="grid py-2 xl:py-0">
                  <div class="col-3">
                    <div class="mt-2">
                      <i class="fa-solid fa-ruler-combined mr-2 vertical-align-baseline"></i>
                      <span class="font-medium vertical-align-baseline">{{ item.locationStoragesGroup.group.size | number }} m</span>
                      <span class="font-medium text-sm vertical-align-top">2</span>
                    </div>
                  </div>
                  <div class="col-7 lg:col-9">
                    <label class="mb-2 mr-1 font-bold" for="count">Ilość: </label>
                    <p-inputNumber id="count" class="border-1 surface-border border-round w-6" inputId="count"
                                   inputStyleClass="text-center py-2 px-1 border-transparent w-6" [showButtons]="true"
                                   buttonLayout="horizontal" spinnerMode="horizontal" [min]="1" [(ngModel)]="this.reservationDetails[cartIndex].quantity"
                                   decrementButtonClass="p-button-text w-4" incrementButtonClass="p-button-text w-4"
                                   incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                   (onInput)="modifyReservation($event, item);">
                    </p-inputNumber>
                  </div>
                </div>
                <div class="grid w-full lg:w-auto">
                  <div class="col-12 flex flex-column lg:block">
                    @if (item.locationStoragesGroup.nettoUnitPromoPrice != null) {
                      <span>
                        {{ item.locationStoragesGroup.nettoUnitPromoPrice * item.quantity | currency:'PLN' }} netto |
                        {{ item.locationStoragesGroup.nettoUnitPromoPrice * item.quantity * 1.23 | currency:'PLN' }}
                        brutto / msc
                      </span>
                    } @else {
                      <span>
                        {{ item.locationStoragesGroup.nettoUnitPrice * item.quantity | currency:'PLN' }} netto |
                        {{ item.locationStoragesGroup.nettoUnitPrice * item.quantity * 1.23 | currency:'PLN' }}
                        brutto / msc
                      </span>
                    }
                    <!--<label class="mb-2 mr-1 font-bold" for="calendar">Rezerwacja od: </label>
                    <p-calendar id="calendar" class="w-full lg:w-fit" inputId="calendar" dateFormat="dd.mm.yy" [firstDayOfWeek]="1" [showWeek]="true"
                                [showClear]="true" [minDate]="minDate" [defaultDate]="this.minDate" [disabledDays]="[0,6]" [touchUI]="true"
                                inputStyleClass="w-full md:w-fit">
                      <ng-template pTemplate="footer">
                        <div class="grid">
                          <div class="col-12 text-center">
                            <p class="font-semibold">Wolne od {{minDate | date:'dd.MM.yyyy'}}</p>
                          </div>
                        </div>
                      </ng-template>
                    </p-calendar>-->
                  </div>
                </div>
              </div>
              <!-- <div class="flex">
                <p class="font-bold text-3xl text-900 mt-0 mb-2">{{item.unitPrice}} zł netto</p>
              </div> -->
            </div>
          </article>
        </div>
      }
      <div class="surface-card p-4 shadow-2 border-round">
        <div class="mb-2 flex align-items-center justify-content-between">
          <div class="flex align-items-center">
            <i class="fa-solid fa-piggy-bank text-500 mr-2 text-xl"></i>
            <span class="text-3xl font-medium text-900 mb-2">Wyliczenie</span>
          </div>
        </div>
        <div class="font-medium text-500 mb-3 text-left">Zobacz, ile zaoszczędzisz!</div>

        <!-- <div class="grid mt-auto px-5">
          <div class="col-6">

          </div>
          <div class="col-6">

          </div>
        </div> -->
        <div class="grid nested-grid">
          <div class="col-12 xl:col-7">
            <div class="grid">
              <p-divider class="w-full px-2 mb-3"></p-divider>
              <div class="col-12 flex flex-column md:block">
                <label class="mb-2 mr-1 font-bold" for="calendar">Rezerwacja od: </label>
                <p-calendar id="calendar" class="w-full md:w-fit" inputId="calendar" dateFormat="dd.mm.yy" [firstDayOfWeek]="1" [showWeek]="true"
                            [showClear]="true" [minDate]="minDate" [maxDate]="maxDate" [defaultDate]="this.minDate" [disabledDays]="[0,6]" [disabledDates]="this.daysoff" [touchUI]="true"
                            inputStyleClass="w-full md:w-fit" (onSelect)="setBeginBooking();" [(ngModel)]="reservationStartDate">
                  <ng-template pTemplate="footer">
                    <div class="grid">
                      <div class="col-12 text-center">
                        <p>
                          Wybierz datę od <span class="font-semibold">{{minDate | date:'dd.MM.yyyy'}}</span> do <span class="font-semibold">{{maxDate | date:'dd.MM.yyyy'}}</span>.
                        </p>
                      </div>
                    </div>
                  </ng-template>
                </p-calendar>
              </div>
              @if (
                  (dayjs().hour() >= 16 && dayjs().minute() > 15) ||
                  (dayjs().hour() >= 17) ||
                  (dayjs().day() == 6 || dayjs().day() == 0) ||
                  (dayjs().day() == 1 && dayjs().hour() < 10) ||
                  this.daysoff.includes(dayjs().toDate())
                ) {
                <div class="col-12 flex flex-column md:block">
                  <div class="flex align-items-start p-4 bg-blue-100 border-round border-1 border-blue-300">
                    <i class="fa-solid fa-circle-info text-blue-900 text-2xl mr-3"></i>
                    <div class="mr-3 w-full">
                      <div class="text-blue-900 font-medium text-xl mb-2 line-height-1">Informacja</div>
                      <p class="m-0 p-0 text-blue-700 mb-3 line-height-3">
                        Nasze Biuro Obsługi Klientów jest już nieczynne.
                      </p>
                      <p class="m-0 p-0 text-blue-700 mb-3 line-height-3">
                        Dostęp do kontenerów otrzymasz w najbliższym dniu roboczy, od godziny 10.
                      </p>
                    </div>
                  </div>
                </div>
              }
              <div class="col-12">
                <div class="grid">
                  <div class="col-5 sm:col-3 pt-4">
                    <span class="font-bold md:mx-3 md:mt-2" [ngClass]="{'text-gray-500': stateValue === 2}">Okres wynajmu: </span>
                  </div>
                  <div class="hidden sm:block sm:col-5 pt-3">
                    <p-slider class="mx-5 mt-4 w-full" [(ngModel)]="rentalPeriod" [animate]="true" [step]="1" [min]="1" [max]="24"
                              [disabled]="this.stateValue === 2" (onChange)="this.creatorService.setSessionStorageItem('rentalPeriod', rentalPeriod); this.calculatePrices();">
                    </p-slider>
                  </div>
                  <div class="col-7 sm:col-4">
                    <p-inputNumber class="text-center mx-1 my-1" inputId="bookLength" [(ngModel)]="rentalPeriod" [showButtons]="true" [step]="1" [min]="1" [max]="24" [disabled]="this.stateValue === 2" [suffix]="' ' + this.creatorService.getMonthString(rentalPeriod)" inputStyleClass="w-full" (onInput)="this.creatorService.setSessionStorageItem('rentalPeriod', rentalPeriod); this.calculatePrices();">
                    </p-inputNumber>
                  </div>
                </div>
              </div>
              <p-divider class="w-full px-2 mb-3"></p-divider>
              <!--     Chwilowo wyłączone, ale oprogramować backend.     -->
              <!--<div class="col-12">
                <p-selectButton [options]="stateOptions" [(ngModel)]="stateValue" optionLabel="label"
                  optionValue="id" (onOptionClick)="optionClick($event)"></p-selectButton>
              </div>-->
              <div class="text-900 font-medium text-xl mt-4 lg:mt-0 mb-4 px-2">Rodzaj abonamentu</div>
              <div class="col-12 flex flex-column lg:flex-row field">
                <div
                  class="flex justify-content-between p-3 border-round border-1 surface-border w-full mr-3 hover:border-primary cursor-pointer"
                  (click)="typeSubscription = 'monthly';" [ngClass]="{'border-primary surface-50': typeSubscription === 'monthly'}">
                  <div class="mr-3 lg:mr-0">
                    <div class="text-900 font-bold mb-2">Miesięczny</div>
                    <span class="text-600">
                  <p>Opłacasz pierwszy miesiąc podczas rezerwacji.<br />Resztę, co miesiąc.</p>
                </span>
                  </div>
                  <div class="flex justify-content-between align-items-center">
                    <span class="text-primary mr-2 font-medium">{{ this.optionsPrice['monthly'] | currency:'PLN' }} netto | {{ this.optionsPrice['monthly'] * 1.23 | currency:'PLN' }} brutto</span>
                    <p-radioButton name="typeSubscriptionMonthly" value="monthly" [(ngModel)]="typeSubscription"></p-radioButton>
                  </div>
                </div>
                <div
                  class="flex justify-content-between p-3 mt-3 lg:mt-0 border-round border-1 surface-border w-full hover:border-primary cursor-pointer"
                  (click)="typeSubscription = 'all';"
                  [ngClass]="{'border-primary surface-50': typeSubscription === 'all'}">
                  <div class="mr-3 lg:mr-0">
                    <div class="text-900 font-bold mb-2">Jednorazowy</div>
                    <span class="text-600">
                      Opłata z góry, za cały okres wynajmu.
                      <p-tag severity="info" value="Zawiera rabaty!" [pTooltip]="tooltipContent"
                             tooltipPosition="top">
                      </p-tag>
                      <ng-template #tooltipContent>
                        <div class="flex align-items-center">
                          <p-image src="https://primefaces.org/cdn/primeng/images/primeng.svg" alt="Image" width="20"
                                   height="20" class="mr-2">
                          </p-image>
                          <span>
                            Atrakcyjne rabaty przy<br /> 3 / 6-cio / 12-sto miesięcznym wynajmie oraz dłuższych.
                          </span>
                        </div>
                      </ng-template>
                    </span>
                  </div>
                  <div class="flex justify-content-between align-items-center">
                    <span class="text-primary mr-2 font-medium"> {{ this.optionsPrice['all'] | currency:'PLN' }} netto | {{ this.optionsPrice['all'] * 1.23 | currency:'PLN' }} brutto</span>
                    <p-radioButton name="typeSubscriptionAll" value="all" [(ngModel)]="typeSubscription"></p-radioButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="field col-12 xl:col-5">
            <div class="text-center p-3 h-full surface-100">
              <p-divider class="w-full"></p-divider>
              @if (this.typeSubscription.length > 0) {
                <div class="flex justify-content-between align-items-center my-1">
                  <span class="text-600 font-bold">Suma (netto): </span>
                  <span class="text-900 font-medium text-4xl"> {{ this.optionsPrice[this.typeSubscription] | currency:'PLN' }} </span>
                </div>
                <div class="flex justify-content-between align-items-center my-1">
                  <span class="text-600 font-bold">Podatek VAT (23%): </span>
                  <span class="text-900 font-medium text-4xl"> {{ (this.optionsPrice[this.typeSubscription] * 0.23 ) | currency:'PLN' }} </span>
                </div>
                <!--<div class="w-full">
                  <div class="field">
                    <p-inputGroup class="p-inputgroup">
                      <p-floatLabel class="w-full">
                        <input class="ml-1 text-center w-full" style="border-top-right-radius: 0; border-bottom-right-radius: 0;" pInputText id="promoInput"
                               type="text"
                               [(ngModel)]="discountCode" />
                        <label class="md:mx-3 vertical-align-sub text-600 text-sm sm:text-base" for="promoInput">Wpisz kod rabatowy</label>
                      </p-floatLabel>
                      <button class="w-8 sm:w-7 text-sm sm:text-base" type="button" pButton pRipple [label]="validCode ? 'Usuń kod' : 'Zastosuj kod'"
                              (click)="discountBtnClick($event);">
                      </button>
                    </p-inputGroup>
                    &lt;!&ndash; @ToDo: popracować nad walidacją. &ndash;&gt;
                    <div *ngIf="!this.validCode && this.discountCode != ''" class="flex align-items-start p-4 p-message p-message-error border-round border-1">
                      <i class="pi pi-times-circle text-2xl mr-3"></i>
                      <div class="mr-3">
                        <div class="font-medium text-xl mb-3 line-height-1">Validation Errors</div>
                        <ul class="m-0 p-0 ml-3">
                          <li class="p-1">Quis commodo odio aenean sed adipiscing diam.</li>
                          <li class="p-1">Risus pretium quam vulputate dignissim suspendisse.</li>
                          <li class="p-1">Bibendum enim facilisis gravida neque convallis a cras semper.</li>
                        </ul>
                      </div>
                      <div class="ml-auto">
                        <a pRipple class="inline-flex align-items-center justify-content-center ml-auto border-circle hover:bg-pink-50 no-underline cursor-pointer transition-colors transition-duration-150" style="width:1.5rem;height:1.5rem">
                          <i class="pi pi-times"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>-->
                @if (this.rentalPeriod > 2 && typeSubscription.length > 0 && typeSubscription !== 'monthly') {
                  <div class="flex justify-content-between align-items-center mt-4 mb-3">
                    <span class="text-600 font-medium">Rabat: </span>
                    <span class="text-900 text-2xl text-red-500"></span>
                  </div>
                  <div class="surface-card shadow-2 p-3 border-round">
                    <div class="flex justify-content-between mb-3 font-bold">
                      <div>
                        <span class="block text-500 text-xl font-medium mb-3">Oszczędzasz</span>
                        <div class="text-900 font-medium text-3xl text-red-500">
                          {{countDiscount(this.optionsPrice[this.typeSubscription] * 1.23 ) | currency:'PLN' }}
                        </div>
                      </div>
                      <div class="flex align-items-center justify-content-center bg-orange-500 border-round"
                           style="width:2.5rem;height:2.5rem">
                        <i class="fa-solid fa-hand-holding-dollar text-orange-50 text-2xl"></i>
                      </div>
                    </div>
                    <span class="text-green-500 font-medium text-2xl">
                        ~
                      {{
                        Math.abs((
                          (this.optionsPrice[this.typeSubscription] + countDiscount(this.optionsPrice[this.typeSubscription])) * 1.23 /
                          (this.optionsPrice[this.typeSubscription] * 1.23 )
                        ) - 1 )| percent:'1.2':'pl-PL'
                      }}
                      </span>
                    <span>oszczędności w stosunku do <span class="font-bold text-600">abonamentu miesięcznego</span></span>
                  </div>
                }
                <p-divider class="w-full" styleClass="border-top-1 mt-4"></p-divider>
                <div class="flex justify-content-between align-items-center my-1">
                  <span class="text-600 font-bold">Suma (brutto): </span>
                  <span class="text-900 font-medium text-4xl">
                    {{ (this.optionsPrice[this.typeSubscription] * 1.23 + countDiscount(this.optionsPrice[this.typeSubscription] * 1.23 )) | currency:'PLN' }}
                  </span>
                </div>
              } @else {
                <div class="flex justify-content-between align-items-center my-1">
                  <i class="fa-solid fa-arrow-up block xl:hidden"></i>
                  <i class="fa-solid fa-arrow-left hidden xl:block"></i>
                  <p>Wybierz rodzaj abonamentu</p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    } @else {
      <div class="border-2 border-dashed surface-border flex-auto overflow-y-auto p-3 mx-2 my-3">
        <p>
          Koszyk jest pusty.<br/>
          Dodaj magazyn do koszyka.<br/>
          Potrzebujesz pomocy? Zadzwoń: <a href="tel:+48883839389">+48 883 839 389</a>
        </p>
      </div>
    }

    <div id="slideProducts" class="hidden surface-overlay absolute top-0 right-0 shadow-2 w-75 h-full z-5">
      <div class="flex flex-column h-full p-4">
        <div class="flex align-items-center justify-content-between mb-4">
          <span class="text-900 text-xl font-medium">Wybierz magazyn</span>
          <button pButton pRipple icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" pStyleClass="#slideProducts" leaveToClass="hidden" leaveActiveClass="fadeoutright"></button>
        </div>

        <!-- /#/ecommerce/categorypage With Dropdown Filter and Hover CTA -->
        <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
          <div class="flex justify-content-between flex-wrap">
            <div class="flex align-items-center mb-4 md:mb-0">
              <div class="text-900 font-bold text-3xl">Rodzaje magazynów</div>
              <!-- <p-badge [value]="'76'" styleClass="ml-3 bg-gray-200 text-gray-900 p-0 border-circle"></p-badge> -->
            </div>
          </div>
          <p class="text-600 text-xl">Nullam faucibus, sem et bibendum finibus, sapien ipsum congue felis, sit amet pretium ex nisl ut eros.</p>
          <p-divider styleClass="w-full border-gray-200"></p-divider>
          <div class="grid grid-nogutter align-items-center">
            <!-- <p-multiSelect [options]="brands" [(ngModel)]="selectedBrands" placeholder="Brand" optionLabel="name" styleClass="w-full" [maxSelectedLabels]="2" selectedItemsLabel="{0} brands selected" class="flex-auto lg:flex-1 mb-3 lg:mt-0 w-full mr-0 lg:mr-4 text-900"></p-multiSelect>
            <p-multiSelect [options]="colors" [(ngModel)]="selectedColors" placeholder="Color" optionLabel="name" styleClass="w-full" [maxSelectedLabels]="2" selectedItemsLabel="{0} colors selected" class="flex-auto lg:flex-1 mb-3 lg:mt-0 w-full mr-0 lg:mr-4 text-900">
              <ng-template let-value pTemplate="selectedColors">
                <div *ngFor="let color of selectedColors">
                  <div class="w-3rem h-3rem border-circle cursor-pointer border-none"></div>
                  <div>{{color.name}}</div>
                </div>
                <div *ngIf="!selectedColors || selectedColors.length === 0">Color</div>
              </ng-template>
              <ng-template let-color pTemplate="item">
                <div class="flex align-items-center">
                  <div class="w-2rem h-2rem border-circle {{color.class}} cursor-pointer border-none"></div>
                  <div class="text-900 ml-2">{{color.name}}</div>
                </div>
              </ng-template>
            </p-multiSelect> -->
            <!-- <p-multiSelect [options]="prices" [(ngModel)]="selectedPrice" placeholder="Przedział cenowy" optionValue="id" optionLabel="range" styleClass="w-full" [maxSelectedLabels]="2" selectedItemsLabel="{0} prices selected" class="flex-auto lg:flex-1 mb-3 lg:mt-0 lg:col w-full mr-0 lg:mr-4 text-900"></p-multiSelect>
            &lt;!&ndash;<p-toggleButton [(ngModel)]="checked1"  onLabel="Sustainable" offLabel="Unsustainable" onIcon="pi pi-check" offIcon="pi pi-times" styleClass="mb-3 lg:mt-0 mr-4 flex-shrink-0 w-12rem"></p-toggleButton>
            <p-toggleButton [(ngModel)]="checked2"  onLabel="Sale" offLabel="Not Sale" onIcon="pi pi-check" offIcon="pi pi-times" styleClass="mb-3 lg:mt-0 mr-4 flex-shrink-0 w-9rem"></p-toggleButton>&ndash;&gt;
            <div #sortDiv>
              <button type="button" pButton icon="pi pi-sort-alt" class="p-button-outlined p-button-secondary w-10rem p-2" iconPos="right" label="Sortuj według" (click)="menu.toggle($event)"></button>
              <p-menu #menu [popup]="true" [model]="sortItems" [appendTo]="'sortDiv'"></p-menu>
            </div>
            <a pRipple tabindex="0" class="cursor-pointer flex align-items-center mb-3 lg:mt-0 text-900">Clear All</a> -->
          </div>
        </div>
        <section class="grid">
          @if (assortment.length > 0) {
            @for (item of assortment; track $index) {
              <div class="col-12 xl:col-12">
                <article
                  class="flex flex-column md:flex-row w-full gap-3 p-3 border-round border-1 surface-border surface-card">
                  <div class="relative">
                    <p-image src="{{item.thumbnailUrl}}" alt="Image" width="250" class="border-round w-full h-full md:w-14rem md:h-14rem"></p-image>
                    <!-- <p class="absolute px-2 py-1 border-round-lg text-sm font-normal text-white mt-0 mb-0" style="background-color: rgba(255, 255, 255, 0.3); backdrop-filter: blur(10px); top: 3%; left: 3%;">
                      @if (item.promoPrice !== null) {
                        <span class="text-red-500 font-bold">
                          <span class="text-gray-50 font-normal promoPrice">{{ item.unitPrice | currency:'PLN'}} netto</span>
                          <br/>
                        </span>
                        <span>&nbsp; {{ item.promoPrice | currency:'PLN' }} netto</span>
                      } @else {
                        <span>{{ item.unitPrice | currency:'PLN' }} netto</span>
                      }
                    </p> -->
                  </div>
                  <div class="flex flex-column w-full gap-3">
                    <div class="flex flex-wrap w-full justify-content-between align-items-center gap-3">
                      <p class="font-medium text-lg mt-0 mb-0">{{ item.group.name }}</p>
                      <button type="button" pButton icon="fa-solid fa-cart-plus"
                              class="p-button p-button-text text-xl p-2 p-button-rounded"
                              pStyleClass="#slideProducts" leaveToClass="hidden" leaveActiveClass="fadeoutright"
                              (click)="addToReservation($event, item);">
                      </button>
                    </div>
                    <div class="flex flex-wrap justify-content-between xl:h-2rem mt-0">
                      <div class="grid w-full py-2 xl:py-0">
                        <div class="col-4">
                          <div class="mt-2">
                            <i class="fa-solid fa-ruler-combined mr-2 vertical-align-baseline"></i>
                            <span class="font-medium vertical-align-baseline">{{ item.group.size | number }} m</span>
                            <span class="font-medium text-sm vertical-align-top">2</span>
                          </div>
                        </div>
                        <div class="col-8">
                          <!-- <label class="mb-2 mr-1 font-bold" for="count">Ilość: </label>
                          <p-inputNumber class="border-1 surface-border border-round w-6" inputStyleClass="text-center py-2 px-1 border-transparent w-6" [showButtons]="true" buttonLayout="horizontal" spinnerMode="horizontal" [min]="1" decrementButtonClass="p-button-text w-4" incrementButtonClass="p-button-text w-4" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
                          </p-inputNumber> -->
                          <p class="font-bold text-3xl text-900 mt-0 mb-2">
                            {{ item.nettoUnitPrice | currency: 'PLN' }} netto | {{ item.nettoUnitPrice * 1.23 | currency: 'PLN' }} brutto / msc
                          </p>
                        </div>
                      </div>
                      <!-- <div class="grid">
                        &lt;!&ndash; <div class="col-12">
                          <label class="mb-2 mr-1 font-bold" for="calendar">Rezerwacja od: </label>
                          <p-calendar class="w-fit" inputId="calendar" dateFormat="dd.mm.yy" [firstDayOfWeek]="1" [showWeek]="true"
                                      [showClear]="true" >
                            <ng-template pTemplate="footer">
                              <div class="grid">
                                <div class="col-12 text-center">
                                  <p class="font-semibold">Footer</p>
                                </div>
                              </div>
                            </ng-template>
                          </p-calendar>
                        </div> &ndash;&gt;
                      </div> -->
                    </div>
                    <!-- <div class="flex">
                      <p class="font-bold text-3xl text-900 mt-0 mb-2">{{item.unitPrice}} zł netto</p>
                    </div> -->
                  </div>
                </article>
              </div>
            }
          } @else {
            <div class="border-2 border-dashed surface-border flex-auto overflow-y-auto p-3">
              <p>
                Brak produktów.<br />
                Zadzwoń: <a href="tel:+48883839389">+48 883 839 389</a>
              </p>
            </div>
          }
        </section>
      </div>
    </div>
  </section>

  <div class="grid flex py-3 sm:py-4">
    <div class="col-12 lg:col-12">
        <span class="p-buttonset inline-block h-full" [pTooltip]="tooltipNext" tooltipPosition="right" [tooltipDisabled]="reservationDetails.length != 0">
          <p-button label="Wstecz" icon="pi pi-angle-left" iconPos="left" routerLink="/creator"></p-button>
          <p-button label="Następny" [disabled]="reservationDetails.length == 0 || this.lock" icon="pi pi-angle-right" iconPos="right" (click)="goToForm($event);">
            <ng-template #tooltipNext>
              <div class="flex align-items-center">
                  <span> Dodaj magazyn do koszyka.</span>
              </div>
            </ng-template>
          </p-button>
        </span>
    </div>
  </div>

</div>

<p-toast position="center" key="lockCart" [preventOpenDuplicates]="true" [preventDuplicates]="this.lock" [breakpoints]="{'5120px': {width: '50%'}, '1300px': {width: '70vw', left: '40%'}, '600px': {width: '80vw', left: '45%'}}" [life]="30000">
  <ng-template let-message pTemplate="message">
    <div class="flex align-items-start py-4 px-1 md:px-4 w-full">
      <i class="pi pi-exclamation-triangle text-yellow-900 text-2xl mr-3"></i>
      <div class="mr-0 md:mr-3 w-full">
        <div class="text-yellow-900 font-medium text-xl mb-2 line-height-1">{{ message.summary }}</div>
        <div class="m-0 p-0 text-yellow-700 mb-3 line-height-3 text-base my-3 text-700" [innerHTML]="message.detail | sanitizeHtml"></div>
        <div class="flex flex-column gap-2">
          <a class="font-medium text-yellow-900 hover:text-yellow-800 mr-4 cursor-pointer select-none transition-colors transition-duration-150" href="tel:+48883839389">Zadzwoń: +48 883 839 389</a>
          <a class="font-medium text-yellow-900 hover:text-yellow-800 cursor-pointer select-none transition-colors transition-duration-150" href="mailto:biuro@przechowalnia24.pl?subject=Większa rezerwacja na {{ this.creatorService.getSessionStorageItem('selectedLocation')?.name }}">Napisz e-mail: biuro&#64;przechowalnia24.pl</a>
        </div>
      </div>
    </div>
  </ng-template>
</p-toast>
