<div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12">
      <p-table #dt1 [value]="locations" [scrollable]="true" scrollHeight="64vh" [tableStyle]="{'min-width': '50rem'}"
               dataKey="id" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5, 10, 15, 25, 50, 100]" [loading]="loading"
               [paginator]="true" [showJumpToPageInput]="true" currentPageReportTemplate="Wiersze: {first} - {last} / {totalRecords}, Strona: {currentPage} / {totalPages}"
               [globalFilterFields]="['name', 'address', 'order', 'promotions.title', 'balance', 'status']" sortMode="multiple"
               [(selection)]="selectedLocation" styleClass="p-datatable-striped"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-row justify-content-between">
            <div class="flex align-items-center justify-content-center">
              <!-- <div *ngIf="selectedCustomers !== undefined" class="grid align-items-center justify-content-center">
                <div class="col-8">
                  <button pButton label="Zmień statusy" class="p-button-outlined p-button-info" icon="fa-solid fa-pen-to-square"></button>
                </div>
                <div class="col-4">
                  <button pButton label="Usuń" class="p-button-outlined p-button-danger" icon="fa-regular fa-trash-can"></button>
                </div>
              </div> -->
            </div>
            <div class="flex align-items-center justify-content-between">
              <span class="p-input-icon-left mr-1">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="searchInput(dt1, $event);" placeholder="Search keyword" />
              </span>
              <button pButton label="Wyczyść filtry" class="p-button-outlined ml-2" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 2rem;"></th>
            <!--<th style="width: 5rem;">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>-->
            <th style="width: 10rem;" pSortableColumn="name">
              <div class="flex align-items-center justify-content-around">
                Name
                <p-sortIcon field="name"></p-sortIcon>
                <p-columnFilter class="ml-1" type="text" field="name" display="toolbox"></p-columnFilter>
              </div>
            </th>
            <th  style="width: 8rem;" pSortableColumn="thumbnail">
              <div class="flex align-items-center justify-content-around">
                Miniatury
                <!--<p-sortIcon field="representative.name"></p-sortIcon>
                <p-columnFilter field="representative" matchMode="in" display="toolbox" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">Agent Picker</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-multiSelect [ngModel]="value" [options]="representatives" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <img [alt]="option.label" src="https://primefaces.org/cdn/primeng/images/demo/avatar/{{ option.image }}" width="24" class="vertical-align-middle" />
                          <span class="ml-1 mt-1">{{ option.name }}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>-->
              </div>
            </th>
            <!--<th style="min-width:12rem" pSortableColumn="status">
              <div class="flex align-items-center justify-content-around">
                Status
                <p-sortIcon field="status"></p-sortIcon>
                <p-columnFilter field="status" matchMode="equals" display="toolbox">
                  <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                    <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any">
                      <ng-template let-option pTemplate="item">
                        <p-tag [value]="option.value" [severity]="getSeverity(option.label)"></p-tag>
                      </ng-template>
                    </p-dropdown>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>-->
            <th style="width: 5rem;" pSortableColumn="available">
              <div class="flex align-items-center justify-content-around">
                Dostępna
                <p-sortIcon field="available"></p-sortIcon>
                <p-columnFilter type="boolean" field="available" display="toolbox"></p-columnFilter>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-location let-rowIndex="rowIndex" let-expanded="expanded">
          <tr [pSelectableRow]="locations" [pSelectableRowIndex]="rowIndex" (click)="openRow($event, location.id);">
            <td>
              <button type="button" pButton pRipple [pRowToggler]="location" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              <p-tag *ngIf="!expanded" severity="warning">
                <i class="fa-solid fa-exclamation mx-1"></i>
                <span class="">Magazyny</span>
              </p-tag>
            </td>
            <!--<td>
              <p-tableCheckbox [value]="customer"></p-tableCheckbox>
            </td>-->
            <td>
              {{ location.name }}
            </td>
            <td class="flex flex-column align-items-center justify-content-center">
              <img class="vertical-align-middle h-8rem" [alt]="location.thumbnail?.name" src="{{ location.thumbnail?.url }}" />
            </td>
            <td class="text-center">
              <i class="pi" [ngClass]="{ 'text-green-500 pi-check-circle': location.available, 'text-red-500 pi-times-circle': !location.available }"></i>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-product>
          <tr>
            <td colspan="7">
              <div class="p-3">
                <p-table [value]="product.orders" dataKey="id">
                  <ng-template pTemplate="header">
                    <tr>
                      <th pSortableColumn="id">Id <p-sortIcon field="price"></p-sortIcon></th>
                      <th pSortableColumn="customer">Customer <p-sortIcon field="customer"></p-sortIcon></th>
                      <th pSortableColumn="date">Date <p-sortIcon field="date"></p-sortIcon></th>
                      <th pSortableColumn="amount">Amount <p-sortIcon field="amount"></p-sortIcon></th>
                      <th pSortableColumn="status">Status <p-sortIcon field="status"></p-sortIcon></th>
                      <th style="width: 4rem"></th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-order>
                    <tr>
                      <td>{{ order.id }}</td>
                      <td>{{ order.customer }}</td>
                      <td>{{ order.date }}</td>
                      <td>{{ order.amount | currency: 'USD' }}</td>
                      <td>
                        <p-tag [value]="order.status" [severity]="getStatusSeverity(order.status)"></p-tag>
                      </td>
                      <td><p-button type="button" icon="pi pi-plus"></p-button></td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="6">There are no order for this product yet.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No customers found.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
