<div class="px-2 pt-4 pb-2 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12">
      <p-table #dt1 [value]="clients" [scrollable]="true" scrollHeight="64vh" [tableStyle]="{'min-width': '50rem'}"
               dataKey="id" [rows]="10" [showCurrentPageReport]="false" [rowsPerPageOptions]="[5, 10, 15, 25, 50, 100]" [loading]="loading"
               [paginator]="true" [showJumpToPageDropdown]="true" paginatorLocale="pl"
               currentPageReportTemplate="Wiersze: {first} - {last} / {totalRecords}, Strona: {currentPage} / {totalPages}"
               [globalFilterFields]="['id', 'name', 'status', 'email', 'phone']" sortMode="multiple"
               [(selection)]="selectedClients" styleClass="p-datatable-striped"
      >
        <ng-template pTemplate="caption">
          <div class="flex flex-row justify-content-around">
            <!-- <div class="flex align-items-center justify-content-center">
              <div *ngIf="selectedCustomers !== undefined" class="grid align-items-center justify-content-center">
                <div class="col-8">
                  <button pButton label="Zmień statusy" class="p-button-outlined p-button-info" icon="fa-solid fa-pen-to-square"></button>
                </div>
                <div class="col-4">
                  <button pButton label="Usuń" class="p-button-outlined p-button-danger" icon="fa-regular fa-trash-can"></button>
                </div>
              </div>
            </div> -->
            <div class="flex align-items-center justify-content-between">
              <span class="p-input-icon-left mr-1">
                <i class="fa-solid fa-magnifying-glass"></i>
                <input pInputText type="text" class="w-full" (input)="searchInput(dt1, $event);" placeholder="Wyszukaj klienta..." />
              </span>
              <button pButton label="Wyczyść filtry" class="p-button-outlined ml-2" icon="fa-solid fa-filter-circle-xmark" (click)="clear(dt1)"></button>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 5rem;">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th style="min-width:12rem;" pSortableColumn="name">
              <div class="flex align-items-center">
                Nazwa
                <p-sortIcon class="ml-1" field="name"></p-sortIcon>
                <p-columnFilter field="name" display="menu" />
              </div>
            </th>
            <th style="min-width:12rem;" pSortableColumn="subject">
              <div class="flex align-items-center">
                Typ
                <p-sortIcon class="ml-1" field="subject"></p-sortIcon>
                <p-columnFilter field="subject" matchMode="in" display="menu"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false"
                >
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">Filtruj rodzaje klientów</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-filter="filterCallback">
                    <p-multiSelect [options]="clientTypes" optionLabel="label" optionValue="value"
                                   placeholder="Wybierz rodzaj klienta..." display="comma" (onChange)="filter($event.value);"
                                   [showClear]="true" [displaySelectedLabel]="true" [maxSelectedLabels]="null"
                    >
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <span class="py-2">{{ option.label }}</span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th style="min-width:15rem;" pSortableColumn="status">
              <div class="flex align-items-center">
                Status
                <p-sortIcon field="status"></p-sortIcon>
                <p-columnFilter field="reservationStatus" matchMode="in" display="menu"
                                [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" [showClearButton]="false" [showApplyButton]="false"
                >
                  <ng-template pTemplate="header">
                    <div class="px-3 pt-3 pb-0">
                      <span class="font-bold">Filtruj statusy</span>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="filter" let-filter="filterCallback">
                    <p-multiSelect [(ngModel)]="filteredStatuses" [options]="statuses" optionLabel="label" optionValue="value"
                                   placeholder="Wybierz statusy..." display="comma" (onChange)="filter($event.value);"
                                   [showClear]="true" [displaySelectedLabel]="true" [maxSelectedLabels]="null"
                    >
                      <ng-template let-option pTemplate="item">
                        <div class="inline-block vertical-align-middle">
                          <p-tag [value]="option.label" [severity]="getSeverity(option.value)" />
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </ng-template>
                </p-columnFilter>
              </div>
            </th>
            <th style="min-width:13rem" pSortableColumn="">
              <div class="flex align-items-center justify-content-around">
                Data rejestracji w systemie
              </div>
            </th>
            <th style="min-width:12rem" pSortableColumn="">
              <div class="flex align-items-center justify-content-around">
                E-mail
                <p-sortIcon field=""></p-sortIcon>
              </div>
            </th>
            <th style="min-width:12rem" pSortableColumn="">
              <div class="flex align-items-center justify-content-around">
                Telefon
                <p-sortIcon field=""></p-sortIcon>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-client let-rowIndex="rowIndex">
          <tr [pSelectableRow]="client" [pSelectableRowIndex]="rowIndex">
            <td>
              <p-tableCheckbox [value]="client"></p-tableCheckbox>
            </td>
            <td>
              <a class="cursor-pointer text-blue-500 white-space-nowrap" routerLink="./details/{{ client.id }}">{{ client.name }}</a>
            </td>
            <td>
              {{ client.subject }}
            </td>
            <td>
              <p-tag [value]="getSeverityLabel(client.status)" [severity]="getSeverity(client.status)">
              </p-tag>
            </td>
            <td>
              {{ client.userRegistrationDate | date: 'dd.MM.yyyy HH:mm:ss' }}
            </td>
            <td class="">
              <span class="mr-2">{{ client.email }}</span>
            </td>
            <td class="">
              <span class="mr-2">{{ client.phone }}</span>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">Brak klientów.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
