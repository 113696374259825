import { Component, OnInit } from '@angular/core';
import { Breadcrumb } from '../../services/breadcrumbs/breadcrumbs.service';
import { BreadcrumbsService } from '../../services/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'arrow-navigator',
  templateUrl: './arrow-navigator.component.html',
  styleUrl: './arrow-navigator.component.scss'
})
export class ArrowNavigatorComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];

  constructor(private breadcrumbsService: BreadcrumbsService) {}

  ngOnInit() {
    this.breadcrumbsService.breadcrumbs$.subscribe((breadcrumbs) => {
      this.breadcrumbs = [{ label: 'Home', url: '/panel' }, ...breadcrumbs];
    });
  }

}
