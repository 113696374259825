import { Component, OnInit } from "@angular/core";
import { DropdownFilterOptions } from 'primeng/dropdown';
import { Location, Yard } from "../../../shared/data/models/location";
import { FetchDataService } from "../../../shared/data/services/fetch-data.service";
import { ActivatedRoute } from "@angular/router";
import { LocationsPromotions } from "../../../shared/data/models/locations-promotions";

export interface Product {
  id?: string;
  code?: string;
  name: string;
  description?: string;
  price?: number;
  quantity?: number;
  inventoryStatus?: string;
  category?: string;
  image?: string;
  rating?: number;
}

/**
 * Represents an option item.
 * @group Interface
 */
export interface SelectItem<T = any> {
  label?: string;
  value: T;
  styleClass?: string;
  icon?: string;
  title?: string;
  disabled?: boolean;
}

export interface City {
  name: string;
  code: string;
}

@Component({
  selector: 'location-details',
  templateUrl: './location-details.component.html',
  styleUrl: './location-details.component.scss'
})
export class LocationDetailsComponent implements OnInit {
  promotionLabels!: LocationsPromotions[];

  selectedPromotionLabel!: LocationsPromotions;

  products!: Product[];

  statuses!: SelectItem[];

  clonedProducts: { [s: string]: Product } = {};

  countries: City[] | undefined;

  selectedCountry!: City;

  filterValue: string | undefined = '';

  location: Location = new Location();

  yards: Yard[] = [];
  selectedYard!: Yard;

  constructor(private fetchData: FetchDataService, private router: ActivatedRoute) {
  }

  ngOnInit() {
    this.router.params.subscribe((params: any) => {
      this.fetchData.getLocation(params.id).subscribe({
        next: (response: any) => {
          if (response.status == 200) {
            // console.log("> Item: ", item);
            this.location = response.body;
            this.yards = response.body.yards;
            this.promotionLabels = response.body.promotions;
          }
        }
      });
    });


    this.products = [
      {
        id: '1000',
        code: 'f230fh0g3',
        name: 'Bamboo Watch',
        description: 'Product Description',
        image: 'bamboo-watch.jpg',
        price: 65,
        category: 'Accessories',
        quantity: 24,
        inventoryStatus: 'INSTOCK',
        rating: 5
      },
      {
        id: '1001',
        code: 'nvklal433',
        name: 'Black Watch',
        description: 'Product Description',
        image: 'black-watch.jpg',
        price: 72,
        category: 'Accessories',
        quantity: 61,
        inventoryStatus: 'OUTOFSTOCK',
        rating: 4
      },
      {
        id: '1002',
        code: 'zz21cz3c1',
        name: 'Blue Band',
        description: 'Product Description',
        image: 'blue-band.jpg',
        price: 79,
        category: 'Fitness',
        quantity: 2,
        inventoryStatus: 'LOWSTOCK',
        rating: 3
      },
      {
        id: '1003',
        code: '244wgerg2',
        name: 'Blue T-Shirt',
        description: 'Product Description',
        image: 'blue-t-shirt.jpg',
        price: 29,
        category: 'Clothing',
        quantity: 25,
        inventoryStatus: 'INSTOCK',
        rating: 5
      },
      {
        id: '1004',
        code: 'h456wer53',
        name: 'Bracelet',
        description: 'Product Description',
        image: 'bracelet.jpg',
        price: 15,
        category: 'Accessories',
        quantity: 73,
        inventoryStatus: 'INSTOCK',
        rating: 4
      },
    ];

    this.statuses = [
      { label: 'In Stock', value: 'INSTOCK' },
      { label: 'Low Stock', value: 'LOWSTOCK' },
      { label: 'Out of Stock', value: 'OUTOFSTOCK' }
    ];

    this.countries = [
      { name: 'Australia', code: 'AU' },
      { name: 'Brazil', code: 'BR' },
      { name: 'China', code: 'CN' },
      { name: 'Egypt', code: 'EG' },
      { name: 'France', code: 'FR' },
      { name: 'Germany', code: 'DE' },
      { name: 'India', code: 'IN' },
      { name: 'Japan', code: 'JP' },
      { name: 'Spain', code: 'ES' },
      { name: 'United States', code: 'US' }
    ];
  }

  onRowEditInit(product: Product) {
    this.clonedProducts[product.id as string] = { ...product };
  }

  onRowEditSave(product: Product) {
    if (product.price && product.price > 0) {
      delete this.clonedProducts[product.id as string];
    }
  }

  onRowEditCancel(product: Product, index: number) {
    this.products[index] = this.clonedProducts[product.id as string];
    delete this.clonedProducts[product.id as string];
  }

  resetFunction({ reset }: DropdownFilterOptions) {
    if (reset) {
      reset();
    }
    this.filterValue = '';
  }

  customFilterFunction(event: KeyboardEvent, options: DropdownFilterOptions) {
    if (options.filter) {
      options.filter(event);
    }
  }

}
