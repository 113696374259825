<div class="px-1 py-6 md:px-2 lg:px-4">
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-3">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Comments</span>
            <div class="text-900 font-medium text-xl">152 Unread</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
               style="width:2.5rem;height:2.5rem">
            <i class="pi pi-comment text-purple-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">85 </span>
        <span class="text-500">responded</span>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-4">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Zamówienia</span>
            <div class="flex flex-column">
              <p-button [label]="'24 nowych'" [text]="true" [outlined]="true" severity="success"></p-button>
              <span class="text-500">zamówień z statusem: <span class="text-600 font-medium text-lg">nowe</span></span>
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
               style="width:2.5rem;height:2.5rem">
            <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 md:col-6 lg:col-4">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Braki magazynów</span>
            <div class="text-900 font-medium text-xl">
              <ul class="list-none p-0 m-0">
                <li class="pb-1">
                  <div class="border-2 border-dashed surface-border border-round surface-0 p-4"></div>
                </li>
                <li class="py-1">
                  <div class="border-2 border-dashed surface-border border-round surface-0 p-4"></div>
                </li>
                <li class="pt-1">
                  <div class="border-2 border-dashed surface-border border-round surface-0 p-4"></div>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex align-items-center justify-content-center bg-orange-100 border-round"
               style="width:2.5rem;height:2.5rem">
            <i class="pi pi-map-marker text-orange-500 text-xl"></i>
          </div>
        </div>
        <!--<span class="text-green-500 font-medium">%52+ </span>
        <span class="text-500">since last week</span>-->
      </div>
    </div>
  </div>
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-4">
      <div class="surface-card shadow-2 p-3 border-round">
        <div class="flex justify-content-between mb-3">
          <div>
            <span class="block text-500 font-medium mb-3">Customers</span>
            <div class="text-900 font-medium text-xl">28441</div>
          </div>
          <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
               style="width:2.5rem;height:2.5rem">
            <i class="pi pi-inbox text-cyan-500 text-xl"></i>
          </div>
        </div>
        <span class="text-green-500 font-medium">520  </span>
        <span class="text-500">newly registered</span>
      </div>
    </div>
  </div>
</div>
